/**
 *
 * Basic Formatting
 *
 **/

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

[hidden] {
  display: none !important;
}

/**
 * Body
 */

body {
  overflow-y: scroll;
  margin: 0;
  font-weight: 400;
  text-align: left;
  text-rendering: optimizeSpeed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100% !important;
  font-family: "Futura PT", sans-serif;
  scroll-behavior: smooth;
}

body.admin {
  background: #f8f8f8;
}

body.no-scrollable {
  overflow: hidden;
}

#root {
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
}

.landing {}

footer {}

/**
 * Headlines
 */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  color: #1e2c4f;
}

h1 {
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  line-height: initial;
}

h2 {
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  line-height: initial;
}

h3 {
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
  line-height: initial;
}

h4 {
  font-size: 1em;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  line-height: initial;
}

h5 {
  font-size: 0.83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
  line-height: initial;
}

h6 {
  font-size: 0.67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
  line-height: initial;
}

/**
 * General Text Formattings
 */

b,
strong {
  font-weight: 700;
}

i,
em,
dfn {
  font-style: italic;
}

address {
  font-style: normal;
}

abbr {
  font-variant: small-caps;
  letter-spacing: 1px;
  cursor: help;
}

sub,
sup {
  position: relative;
  font-size: 10px;
  line-height: 10px;
}

sub {
  top: 4px;
}

sup {
  top: -6px;
}

q,
cite,
blockquote {
  font-style: italic;
}

hr {
  height: 1px;
  margin: 0;
  border: 0;
  padding: 0;
}

p {
  display: block;
  margin-left: 0;
  margin-right: 0;
  line-height: 1.5em;
}

li {
  display: list-item;
  line-height: 1.5em;
}

ul {
  li {
    list-style-type: circle;
    font-family: "Futura PT Book", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    color: #737380;
  }
}

.title {
  margin-top: 50px;
  text-align: center;
}

.disabled {
  background-color: #c2c2c2;
  opacity: 0.5;
  color: white;
  cursor: no-drop;

  &:hover {
    background-color: #a1adb9;
  }
}

.errorBoundary {
  margin-bottom: 0;
  text-align: center;
  color: #fc6060;
  font-size: 16px;
  font-style: italic;
}

.errorMessage {
  text-align: left;
  margin: 0;
  color: #fc6060;
  font-size: 14px;
  font-style: italic;
}

.errorMessage {
  text-align: left;
  margin: 5px 0 0;
  color: #fc6060;
  font-size: 14px;
  font-style: italic;
}

a:active {
  color: inherit;
}

.hidden {
  display: none !important;
}

.rotate0 {
  transition: 0.5s;
  transform: rotate(0deg) !important;
}

.rotate90 {
  transition: 0.5s;
  transform: rotate(90deg) !important;
}

.rotate-90 {
  transition: 0.5s;
  transform: rotate(-90deg) !important;
}

.rotate180 {
  transition: 0.5s;
  transform: rotate(180deg) !important;
}

.rotate270 {
  transition: 0.5s;
  transform: rotate(270deg) !important;
}

.pointer-event {
  pointer-events: none;
}

button {
  outline: none !important;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

.center {
  margin: 0 auto;
  align-self: center;
}

.management {
  border-top: 1px solid #cbcbcb;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  padding-right: 30px;

  &:nth-child(2) {
    width: 100%;
  }
}

.isAdminPageTable {
  width: 100%;
  overflow-y: hidden;
}

.table-noData {
  display: block;
  width: 100%;
  margin: 0 auto;

  p {
    text-align: center;
    margin: 20px;
  }
}

.ta-c {
  text-align: center !important;
}

.mainContent {
  margin-top: 100px;

  @media all and (min-width: 359px) and (max-width: 1200px) {
    padding: 0 25px;
  }
}

.MuiDialog-paperWidthSm {
  max-width: 100% !important;
}

.product {
  @media all and (max-width: 1250px) {
    padding: 0 20px;
  }
}

.coloredLink {
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    font-family: "Futura PT", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #bd1d83;
  }

  img {
    margin-left: 10px;
  }
}

.MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline {
  border-color: #b82ca8 !important;
}

.MuiOutlinedInput-root:hover.MuiOutlinedInput-notchedOutline {
  border-color: #b82ca8 !important;
}

.MuiInputBase-input {
  -webkit-box-shadow: 0 0 0 50px white inset !important;
  -webkit-text-fill-color: #333 !important;
}

.secondary-title {
  margin: 0;
  font-size: 39px;
  color: #1f2024;
  font-weight: 400;

  @media all and (min-width: 0) and (max-width: 900px) {
    font-size: 25px;
  }

  @media all and (min-width: 0) and (max-width: 550px) {
    font-size: 35px;
  }

  @media all and (min-width: 0) and (max-width: 450px) {
    font-size: 25px;
  }
}

.tertiary-title {
  color: rgba(31, 32, 36, 0.61);
  font-family: Futura PT;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: -0.96px;
  margin: 0;

  @media all and (min-width: 0) and (max-width: 900px) {
    font-size: 25px;
  }

  @media all and (min-width: 0) and (max-width: 550px) {
    font-size: 30px;
  }

  @media all and (min-width: 0) and (max-width: 450px) {
    font-size: 25px;
  }
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.homePageBlock {
  display: flex;
  margin-bottom: 200px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  position: relative;

  @media all and (min-width: 0) and (max-width: 768px) {
    margin-bottom: 100px;
  }

  @media all and (min-width: 0) and (max-width: 550px) {
    flex-wrap: wrap;
    margin-bottom: 80px;
  }

  &:nth-child(even) {
    flex-direction: row-reverse;
  }

  &-text {
    width: 49%;

    @media all and (min-width: 0) and (max-width: 550px) {
      width: 100%;
    }
  }

  &-img {
    position: relative;
    margin-right: 60px;

    @media all and (min-width: 0) and (max-width: 900px) {
      width: 45%;
      margin-right: 25px;
    }

    &--right {
      margin-right: 0;
    }

    @media all and (max-width: 1024px) {
      order: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media all and (min-width: 0) and (max-width: 550px) {
      width: 100%;
      margin-bottom: 30px;
      margin-right: 0;
      order: 0;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}

.main-btn {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 20px;
  width: fit-content;
  background-color: #bd1d83;
  color: white;
  padding: 18px 20px;
  border-radius: 12px;
  font-family: Graphik LCG, Futura PT, system-ui;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.255px;
  text-transform: uppercase;
  transition: 0.2s linear;

  &--center {
    margin: 0 auto;
  }

  &--transparent {
    border: 1px solid #000;
    background-color: transparent;
    color: #000;

    &::before {
      content: none !important;
    }

    @media (hover: hover) {
      &:hover {
        border-color: #bd1d83;
        background-color: #bd1d83;
        color: #fff;

        svg path {
          stroke: #fff;
        }
      }
    }
  }

  &--yellow {
    background-color: #ffe602;
    font-weight: 500;
    letter-spacing: -0.01em;
    color: #000;

    &::before {
      content: none !important;
    }
  }

  &__text {
    margin-right: 11px;
  }

  svg {
    transition: transform 0.2s linear;
  }

  img {
    transition: transform 0.2s linear;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f277c6;
    border-radius: 12px;
    z-index: -1;
    transition: bottom .2s linear;
  }

  @media (hover: hover) {
    &:hover::before {
      bottom: 0;
    }

    &:hover {

      svg,
      img {
        transform: translateX(4px);
      }
    }
  }


}

.main {
  position: relative;
  padding: 0 20px;
  overflow: hidden;
}