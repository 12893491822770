.textfieldw {
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 12px;
  backdrop-filter: blur(7px);
  background: rgba(255, 255, 255, 0.3);
  border: 0;
  outline: 0;
}

.css-1ccgq2v-MuiInputBase-root-MuiFilledInput-root::before {
  border: 0 !important;
}

.css-10botns-MuiInputBase-input-MuiFilledInput-input {
  background-color: #fff502 !important;
  border: 1px solid rgba(255, 255, 255, 0.4) !important;
  border-radius: 12px !important;
  backdrop-filter: blur(7px) !important;
  background: rgba(255, 255, 255, 0.3) !important;
  border: 0 !important;
  outline: 0 !important;
}

.css-1ccgq2v-MuiInputBase-root-MuiFilledInput-root {
  background-color: #fff502 !important;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 12px;
  backdrop-filter: blur(7px);
  background: rgba(255, 255, 255, 0.3);
  border: 0;
  outline: 0;
}

.css-2y464i-MuiInputBase-root-MuiFilledInput-root {
  background-color: #ffc46b !important;
}

.subscribe-form__input {
  margin-right: 10px;
}

// .label[data-shrink="false"]
//     + .MuiInputBase-formControl
//     .css-10botns-MuiInputBase-input-MuiFilledInput-input {
//     border-radius: 12px;
// }

// .MuiInputBase-root
//     MuiFilledInput-root
//     MuiFilledInput-underline
//     MuiInputBase-colorPrimary
//     MuiInputBase-fullWidth
//     MuiInputBase-formControl
//     css-2y464i-MuiInputBase-root-MuiFilledInput-root {
//     border-radius: 12px;
// }
