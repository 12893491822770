.global-presence {
  margin: 100px 0;

  @media all and (min-width: 0) and (max-width: 768px) {
    margin: 40px 0;
  }
}

.global-presence__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 30px;
  row-gap: 24px;

  width: 100%;
  margin-bottom: 140px;

  span {
    background-image: url('../../assets/img/global-presence-decor.svg');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% 20px;
  }

  @media all and (min-width: 768px) and (max-width: 1024px) {}

  @media all and (min-width: 0) and (max-width: 768px) {
    margin-bottom: 40px;
    flex-direction: column;
    align-items: flex-start;
  }
}

.global-presence__title {
  margin: 0;
  font-size: 80px;
  line-height: 106%;
  letter-spacing: -0.04em;
  color: #1f2024;

  @media all and (max-width: 1200px) and (max-width: 1300px) {
    font-size: 70px;
  }

  @media all and (min-width: 0) and (max-width: 768px) {
    font-size: 45px;
  }
}

.global-presence__text {
  max-width: 580px;
  font-size: 21px;
  line-height: 151%;
  letter-spacing: -0.03em;
  color: #1f2024;

  @media all and (min-width: 0) and (max-width: 768px) {
    font-size: 18px;
  }
}

.global-presence__pic {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  @media all and (min-width: 0) and (max-width: 768px) {
    img {
      width: 700px;
      height: auto;
    }
  }
}