.powering-business {
  padding: 80px 0;


  @media all and (min-width: 0) and (max-width: 768px) {
    padding: 40px 0;
  }
}

.powering-business__head {
  width: fit-content;
  margin-bottom: 140px;

  span {
    background-image: url('../../assets/img/powering-business-decor.svg');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% 20px;
  }

  @media all and (min-width: 768px) and (max-width: 1024px) {}

  @media all and (min-width: 0) and (max-width: 768px) {
    margin-bottom: 60px;
  }
}

.powering-business__title {
  margin: 0;
  font-size: 80px;
  line-height: 106%;
  letter-spacing: -0.04em;
  color: #1f2024;

  @media all and (max-width: 1200px) and (max-width: 1300px) {
    font-size: 70px;
  }

  @media all and (min-width: 0) and (max-width: 768px) {
    font-size: 45px;

    span {
      display: block;
    }
  }
}

.powering-business__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 30px;
  row-gap: 80px;

  @media all and (max-width: 1200px) and (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media all and (min-width: 0) and (max-width: 768px) {
    row-gap: 60px;
    column-gap: 12px;
  }
}

.powering-business__item {
  display: grid;
  justify-content: center;
  justify-items: center;
  row-gap: 24px;
  padding: 24px;
  border-radius: 16px;
  background: rgba(244, 244, 244, 0.5);

  @media all and (min-width: 0) and (max-width: 768px) {
    padding: 16px;
    row-gap: 16px;
  }
}

.powering-business__item-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 138px;
  width: 138px;
  height: 138px;
  margin-top: -80px;
  border-radius: 100%;
  background: linear-gradient(180deg, #ffbfe7 0%, #ffeec9 120%);
  overflow: hidden;


  @media all and (min-width: 0) and (max-width: 768px) {
    width: 100px;
    height: 100px;
    min-width: 100px;
    margin-top: -40px;
  }
}

.powering-business__item-text {
  margin: 0;
  font-weight: 600;
  font-size: 27px;
  line-height: 151%;
  letter-spacing: -0.03em;
  text-align: center;
  color: #1f2024;

  @media all and (min-width: 0) and (max-width: 768px) {
    font-size: 20px;

    br {
      display: none;
    }
  }
}