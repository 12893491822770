.careers {
  padding: 100px 0;

  @media all and (min-width: 0) and (max-width: 768px) {
    padding: 40px 0;
  }

  ul {
    list-style: none;
  }

  li {
    list-style: none;
    font-family: inherit;
    color: inherit;
    font-weight: inherit;
    font-size: inherit;
  }

  a {
    font-family: inherit;
    color: inherit;
    font-weight: inherit;
    font-size: inherit;

    &:hover {
      text-decoration: underline;
      color: #bd1d83;
    }
  }
}

.careers__head {
  width: 100%;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #e3e3e3;

  span {
    background-image: url('../../assets/img/careers-decor.svg');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% 20px;
  }

  @media all and (min-width: 768px) and (max-width: 1024px) {}

  @media all and (min-width: 0) and (max-width: 768px) {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
}

.careers__title {
  margin: 0 0 20px 0;
  font-size: 80px;
  line-height: 106%;
  letter-spacing: -0.04em;
  color: #1f2024;

  @media all and (max-width: 1200px) and (max-width: 1300px) {
    font-size: 70px;
  }

  @media all and (min-width: 0) and (max-width: 768px) {
    font-size: 45px;
  }
}

.careers__text {
  max-width: 560px;
  margin: 0;
  font-size: 21px;
  line-height: 151%;
  letter-spacing: -0.03em;
  color: #1f2024;

  @media all and (min-width: 0) and (max-width: 768px) {
    font-size: 18px;
  }
}

.careers__content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  column-gap: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #e3e3e3;

  &+.careers__content {
    padding-top: 20px;
  }

  @media all and (min-width: 0) and (max-width: 768px) {
    flex-direction: column;
    row-gap: 20px;
  }
}

.careers__manager {
  flex: 0 1 50%;

  @media all and (min-width: 0) and (max-width: 768px) {
    flex: 0 1 auto;
  }
}

.careers__label {
  margin: 0 0 16px 0;
  font-weight: 600;
  font-size: 40px;
  line-height: 151%;
  letter-spacing: -0.03em;
  color: #1f2024;

  @media all and (min-width: 0) and (max-width: 768px) {
    font-size: 28px;
  }
}

.careers__rows {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.careers__row {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.careers__icon {
  width: 20px;
  height: 20px;
}

.careers__descr {
  margin: 0;
  font-size: 19px;
  line-height: 175%;
  letter-spacing: -0.03em;
  color: #1f2024;

  @media all and (min-width: 0) and (max-width: 768px) {
    font-size: 16px;
  }
}

.careers__info {
  flex: 0 1 50%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  @media all and (min-width: 0) and (max-width: 768px) {
    flex: 0 1 auto;
  }
}

.careers__info-descr {
  margin: 0;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  -ms-line-clamp: 4;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  display: -webkit-box;
  display: box;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  box-orient: vertical;

  transition: all 0.3s ease;

  font-size: 19px;
  line-height: 175%;
  letter-spacing: -0.03em;
  color: #1f2024;

  @media all and (min-width: 0) and (max-width: 768px) {
    font-size: 16px;
  }

  &.view-more {
    -ms-line-clamp: inherit;
    -webkit-line-clamp: inherit;
    line-clamp: inherit;
  }
}

.careers__info-descr--default {
  display: block;
}

.careers__btn {
  text-transform: inherit;

  @media all and (min-width: 0) and (max-width: 768px) {
    width: 100% !important;
    justify-content: space-between;
  }
}

.careers__row--top {
  .careers__descr:first-child {
    margin-bottom: auto;
  }
}

.careers__descr-hiring {
  position: relative;
}

.careers__descr-hiring-text {
  display: block;
  position: absolute;
  background-color: #fff502;
  border-radius: 8px;
  left: 40px;
  top: -26px;
  transform: rotate(8deg);
  padding: 2px 10px 4px;
  font-size: 20px;
  line-height: 120%;
  width: fit-content;
}