.connect-wallet {
    flex-direction: row !important;
}

.wallets-bar-wrapper {
    margin-bottom: 30px;
}

.wallets-bar {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 487px;
    padding: 15px 20px;
    border-radius: 11px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    background: #f9f9f9;

    @media all and (min-width: 0) and (max-width: 970px) {
        padding: 5px 10px;
    }

    &__text {
        font-size: 18px;
        color: rgba(31, 32, 36, 0.6);

        @media all and (min-width: 0) and (max-width: 970px) {
            font-size: 14px;
        }
    }

    &__images {
        display: flex;
        align-items: center;
    }

    &__img {
        &:not(:last-child) {
            margin-right: 9px;

            @media all and (min-width: 0) and (max-width: 970px) {
                margin-right: 4px;
                width: 30px;
            }

            @media all and (min-width: 0) and (max-width: 550px) {
                margin-right: 4px;
                width: 35px;
            }
        }

        &--1 {
            background-color: #ffd4a3;
            padding: 5px;
            border-radius: 50%;
        }
    }
}
