.app-info {
  margin: 100px 0;

  @media all and (min-width: 0) and (max-width: 768px) {
    margin: 40px 0;
  }
}

.app-info__head {
  width: 100%;
  margin-bottom: 160px;

  b {
    font-weight: 400;
    color: #bd1d83;
  }

  span {
    background-image: url('../../assets/img/app-info-decor.svg');
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: 100% 20px;
  }

  @media all and (min-width: 768px) and (max-width: 1024px) {
    margin-bottom: 20px;
  }

  @media all and (min-width: 0) and (max-width: 768px) {
    margin-bottom: 0;
  }
}

.app-info__title {
  margin: 0;
  font-size: 80px;
  line-height: 106%;
  letter-spacing: -0.04em;
  color: #1f2024;

  @media all and (max-width: 1200px) and (max-width: 1300px) {
    font-size: 70px;
  }

  @media all and (min-width: 0) and (max-width: 768px) {
    font-size: 45px;
  }
}

.app-info__content {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 300px;
  align-items: center;

  @media all and (max-width: 1200px) and (max-width: 1300px) {
    column-gap: 220px;
  }

  @media all and (min-width: 0) and (max-width: 1100px) {
    column-gap: 40px;
    padding-top: 400px;
  }

  @media all and (min-width: 0) and (max-width: 768px) {
    padding-top: 340px;
  }

  @media all and (min-width: 0) and (max-width: 580px) {
    grid-template-columns: repeat(1, 1fr);
    padding-top: 280px;
    row-gap: 48px;
  }
}

.app-info__arhived {
  display: grid;
}

.app-info__arhived-label {
  margin-bottom: 18px;
  font-size: 30px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #bd1d83;
}

.app-info__row {
  display: flex;
  align-items: center;
  column-gap: 8px;
  flex-wrap: wrap;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  @media all and (min-width: 0) and (max-width: 580px) {
    max-width: 280px;
  }
}

.app-info__icon {}

.app-info__text {
  font-size: 15px;
  line-height: 137%;
  color: #23232e;
}

.app-info__border {
  flex: 1 1 auto;
  min-width: 60px;
  border: 1px dashed #bd1d83;

  @media all and (min-width: 0) and (max-width: 1100px) {
    display: none;
  }
}

.app-info__text--yellow {
  width: fit-content;
  padding: 2px 4px;
  background-color: #fff502;
  font-size: 11px;
  line-height: 137%;
  text-align: center;
  color: #23232e;
}

.app-info__graphik {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;

  img {
    max-width: 100%;
    transform: scale(1.2);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: blur(118px);
    background: linear-gradient(180deg, rgba(255, 142, 214, 0.3) 0%, rgba(255, 196, 62, 0.29) 100%);
    transform: rotate(75deg);
    z-index: -1;
  }

  @media all and (min-width: 0px) and (max-width: 1200px) {
    max-width: 60%;

    img {
      transform: scale(1);
    }

    &::before {
      width: 80%;
      height: 80%;
    }
  }

  @media all and (min-width: 0) and (max-width: 1100px) {
    max-width: 100%;
    width: 500px;
    height: 500px;
    top: 0;
    transform: translate(-50%, 0);

    &::before {
      top: 60%;
      left: -20%;
      transform: rotate(0) translateY(-50%);
      filter: none;
      background-image: url("data:image/svg+xml,%3Csvg width='178' height='126' viewBox='0 0 178 126' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M178 2L29 2C14.0883 2 2 14.0883 2 29V126' stroke='url(%23paint0_linear_675_7618)' stroke-width='3' /%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_675_7618' x1='90' y1='2' x2='90' y2='126' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23BD1D83' /%3E%3Cstop offset='1' stop-color='%23BD1D83' stop-opacity='0' /%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: left center;
    }

    &::after {
      content: '';
      position: absolute;
      right: -20%;
      top: 60%;
      width: 100%;
      height: 100%;
      transform: translateY(-50%);
      background-image: url("data:image/svg+xml,%3Csvg width='178' height='126' viewBox='0 0 178 126' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 2L149 2C163.912 2 176 14.0883 176 29V126' stroke='url(%23paint0_linear_675_7617)' stroke-width='3' /%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_675_7617' x1='88' y1='2' x2='88' y2='126' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FBBF0F' /%3E%3Cstop offset='1' stop-color='%23FBBF0F' stop-opacity='0' /%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right center;
    }
  }

  @media all and (min-width: 0) and (max-width: 767px) {
    width: 400px;
    height: 400px;
  }

  @media all and (min-width: 0) and (max-width: 580px) {
    width: 300px;
    height: 300px;

    &::before {
      width: 20%;
      left: 0;
      top: 70%;
      // background-image: url("data:image/svg+xml,%3Csvg width='21' height='181' viewBox='0 0 21 181' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21 2V2C10.5066 2 2 10.5066 2 21V181' stroke='url(%23paint0_linear_675_8335)' stroke-width='3' /%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_675_8335' x1='11.5' y1='2' x2='11.5' y2='181' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23BD1D83' /%3E%3Cstop offset='1' stop-color='%23BD1D83' stop-opacity='0' /%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
    }

    &::after {
      right: -40%;
      top: 150%;
      height: 600px;
      background-image: url("data:image/svg+xml,%3Csvg width='60' height='602' viewBox='0 0 60 602' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 2L31 2C45.9117 2 58 14.0883 58 29V602' stroke='url(%23paint0_linear_675_8334)' stroke-width='3' /%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_675_8334' x1='29' y1='2' x2='29' y2='602' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FBBF0F' /%3E%3Cstop offset='1' stop-color='%23FBBF0F' stop-opacity='0' /%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
      background-position: top;
      background-size: 100px 600px;
    }
  }
}

.app-info__graphik-desktop {}

.app-info__graphik-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 28px;
  line-height: 92%;
  letter-spacing: -0.04em;
  text-align: center;
  color: #000;

  @media all and (min-width: 0) and (max-width: 580px) {
    font-size: 23px;
  }
}

.app-info__coming {
  display: grid;
  height: 100%;
  align-items: start;
  align-content: baseline;
  text-align: right;

  .app-info__border {
    border-color: #fff502;
  }

  .app-info__row {
    justify-content: flex-end;

    @media all and (min-width: 0) and (max-width: 580px) {
      max-width: 100%;
    }
  }
}

.app-info__coming-label {
  align-self: start;
  margin-bottom: 18px;
  font-size: 30px;
  line-height: 143%;
  letter-spacing: -0.03em;
  text-align: right;
  color: #fbbf0f;
}