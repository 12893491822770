.product-lounge {
  padding: 80px 0;


  @media all and (min-width: 0) and (max-width: 768px) {
    padding: 40px 0;
  }
}

.product-lounge__head {
  width: fit-content;
  margin-bottom: 20px;

  span {
    background-image: url('../../assets/img/product-lounge-decor.svg');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% 20px;
  }

  @media all and (min-width: 768px) and (max-width: 1024px) {}

  @media all and (min-width: 0) and (max-width: 768px) {}
}

.product-lounge__title {
  margin: 0;
  font-size: 80px;
  line-height: 106%;
  letter-spacing: -0.04em;
  color: #1f2024;

  @media all and (max-width: 1200px) and (max-width: 1300px) {
    font-size: 70px;
  }

  @media all and (min-width: 0) and (max-width: 768px) {
    font-size: 45px;
  }
}

.product-lounge__rows {
  display: flex;
  flex-direction: column;
}

.product-lounge__row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
  row-gap: 20px;
  padding: 40px 0;

  &:nth-child(even) {
    .product-lounge__info {
      order: 2;

      @media all and (min-width: 0) and (max-width: 767px) {
        order: inherit;
      }
    }
  }

  @media all and (min-width: 768px) and (max-width: 1024px) {
    padding: 20px 0;
  }

  @media all and (min-width: 0) and (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 40px;
    padding: 20px 0;
  }
}

.product-lounge__info {}


.product-lounge__label {
  margin: 0 0 20px 0;
  font-size: 30px;
  line-height: 143%;
  letter-spacing: -0.03em;
  color: #bd1d83;

  @media all and (min-width: 768px) and (max-width: 1024px) {
    font-size: 28px;
  }

  @media all and (min-width: 0) and (max-width: 768px) {
    font-size: 24px;
  }
}

.product-lounge__list {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-bottom: 34px;

  @media all and (min-width: 768px) and (max-width: 1024px) {
    margin-bottom: 24px;
  }
}

.product-lounge__item {
  display: flex;
  align-items: center;
  column-gap: 20px;
  padding: 24px;
  margin: 0;
  border-radius: 16px;
  background: rgba(244, 244, 244, 0.5);
  list-style: none;

  @media all and (min-width: 768px) and (max-width: 1024px) {
    padding: 16px;
  }

  @media all and (min-width: 0) and (max-width: 767px) {
    padding: 10px;
  }
}

.product-lounge__item-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 46px;
  width: 46px;
  height: 46px;
  border-radius: 100%;
  background-color: #ffcd3a;
}

.product-lounge__item-text {
  font-size: 21px;
  line-height: 135%;
  letter-spacing: -0.03em;
  color: #1f2024;

  @media all and (min-width: 768px) and (max-width: 1024px) {
    font-size: 18px;
  }

  @media all and (min-width: 0) and (max-width: 768px) {
    font-size: 16px;
  }
}

.product-lounge__btn {
  min-width: 204px;
  justify-content: space-between;
}

.product-lounge__info {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  @media all and (min-width: 0) and (max-width: 767px) {
    row-gap: 0;
  }
}

.product-lounge__text {
  margin-bottom: 40px;
  font-size: 21px;
  line-height: 151%;
  letter-spacing: -0.03em;
  color: #1f2024;

  @media all and (min-width: 0) and (max-width: 767px) {
    margin-bottom: 40px;
    font-size: 18px;
  }
}


.product-lounge__picture {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  border-radius: 40px;
  // background: linear-gradient(180deg, #ffbfe7 0%, #ffeec9 100%);

  img {
    max-width: 100%;
    height: auto;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f277c6;
    border-radius: 44px;
    z-index: -1;
  }

  &--transparent {
    background: transparent;

    &::after {
      content: none;
    }
  }

  &--absolute {
    position: absolute;
    right: 0;

    @media all and (min-width: 0) and (max-width: 1340px) {
      position: static;
      margin: auto 0;
      margin-right: -20px;
    }
  }

  @media all and (min-width: 0) and (max-width: 767px) {
    min-height: 300px;
  }
}

.product-lounge__picture--reverse {

  &::after {
    bottom: auto;
    top: -8px;
  }
}