.disclaimer {
  margin: 108px auto 60px;

  p {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    font-family: "Futura PT Book", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #737380;
  }

  a {
    color: #bd1d83;
    text-decoration: underline;
  }

  .cookies h1,
  .cookies h2 {
    font-family: "Futura PT Book", sans-serif;
  }
}