.roadmap {
  position: relative;
  margin: 100px 0;

  @media all and (min-width: 0) and (max-width: 768px) {
    margin: 50px 0;
  }
}


.roadmap__head {
  width: 100%;
  margin-bottom: 50px;

  span {
    background-image: url('../../assets/img/roadmap-decor.svg');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% 20px;
  }

  @media all and (min-width: 768px) and (max-width: 1024px) {}

  @media all and (min-width: 0) and (max-width: 768px) {
    margin-bottom: 20px;
  }
}

.roadmap__title {
  margin: 0;
  font-size: 80px;
  line-height: 106%;
  letter-spacing: -0.04em;
  color: #1f2024;

  @media all and (max-width: 1200px) and (max-width: 1300px) {
    font-size: 70px;
  }

  @media all and (min-width: 0) and (max-width: 768px) {
    font-size: 45px;
  }
}

.roadmap__slider {
  position: static;
  overflow: visible;

  .swiper-pagination {
    position: static;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    margin: 30px 0;

    @media all and (min-width: 0) and (max-width: 768px) {
      column-gap: 0;
      margin: 20px 0 0;
    }
  }

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: #d9d9d9;
    opacity: 1;

    @media all and (min-width: 0) and (max-width: 768px) {
      width: 8px;
      height: 8px;
    }
  }

  .swiper-pagination-bullet-active {
    background-color: #ffcd3a;
  }

  .swiper-button-prev {
    left: -5vw;
    z-index: 3;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: #ffcd3a;
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='14' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 7H2M2 7L8.25926 1M2 7L8.25926 13' stroke='black' stroke-opacity='0.72' stroke-width='1.6' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;

    &.swiper-button-disabled {
      background-color: #f6f6f6;
      opacity: 1;
    }

    @media all and (min-width: 0) and (max-width: 1440px) {
      display: none;
    }
  }

  .swiper-button-next {
    right: -5vw;
    z-index: 3;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: #ffcd3a;
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='14' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 7H13M13 7L6.74074 1M13 7L6.74074 13' stroke='black' stroke-width='1.6' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;

    &.swiper-button-disabled {
      background-color: #f6f6f6;
      opacity: 1;
    }

    @media all and (min-width: 0) and (max-width: 1440px) {
      display: none;
    }
  }

  .swiper-slide {
    display: grid;
    align-content: baseline;
    flex: 0 0 280px;

    &:last-child {
      .roadmap__slide-line {
        flex: 1 1 auto;
      }
    }

    @media all and (min-width: 0) and (max-width: 768px) {
      flex: 0 0 190px;
    }
  }

  .swiper-wrapper {}
}

.roadmap__slide {
  position: relative;
  display: grid;
  align-content: baseline;
  height: 100%;
  min-height: 400px;
  padding: 30px 28px;
  border: 1px solid rgba(151, 151, 151, 0.14);
  border-radius: 11px;
  background: linear-gradient(180deg, #f4f4f4 0%, rgba(244, 244, 244, 0) 100%);

  @media all and (min-width: 0) and (max-width: 768px) {
    padding: 18px;
    min-height: 300px;
  }
}

.roadmap__slide-year {
  margin-bottom: 20px;
  font-size: 45px;
  line-height: 151%;
  letter-spacing: -0.03em;
  color: #eeaa0d;

  @media all and (min-width: 0) and (max-width: 768px) {
    margin-bottom: 10px;
    font-size: 30px;
  }
}

.roadmap__slide-border {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  margin-bottom: 34px;

  @media all and (min-width: 0) and (max-width: 768px) {
    margin-bottom: 24px;
  }
}

.roadmap__slide-circle {
  z-index: 2;
}

.roadmap__slide-line {
  z-index: 1;
  flex: 0 0 300px;
  height: 2px;
  width: 100%;
  background-color: #ffcd3a;

  @media all and (min-width: 0) and (max-width: 768px) {
    flex: 0 0 230px;
  }
}

.roadmap__slide-btc {
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-bottom: 30px;

  font-weight: 600;
  font-size: 24px;
  line-height: 151%;
  letter-spacing: -0.03em;
  text-align: center;
  color: #bd1d83;

  @media all and (min-width: 0) and (max-width: 768px) {
    font-size: 15px;
  }
}

.roadmap__slide-icon {
  width: 28px;
  height: 28px;
  border-radius: 100%;
  object-fit: cover;

  @media all and (min-width: 0) and (max-width: 768px) {
    width: 20px;
    height: 20px;
  }
}

.roadmap__slide-text {
  margin-bottom: 10px;
  font-size: 23px;
  line-height: 116%;
  letter-spacing: -0.03em;
  color: #1f2024;

  @media all and (min-width: 0) and (max-width: 768px) {
    font-size: 18px;
  }
}