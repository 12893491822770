.defi {
    &__text {
        width: 51%;

        @media all and (min-width: 0) and (max-width: 900px) {
            width: 55%;
        }

        @media all and (min-width: 0) and (max-width: 550px) {
            width: 100%;
            margin-right: 0;
        }
    }
    &-text {
        width: 100%;
        max-width: 433px;

        &-title {
            margin: 0 0 24px;
            font-family: "Sen", sans-serif;
            font-style: normal;
            font-weight: 800;
            font-size: 44px;
            line-height: 53px;
            color: #000000;

            span {
                color: #bd1d83;
            }
        }
        &-info {
            margin: 0;
            font-family: "Nunito Sans", sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #737380;
        }
        &-attention {
            display: flex;
            flex-direction: row;
            margin: 28px 0 41px;
            align-items: center;

            img {
                width: 40px;
                height: 40px;
                margin-right: 19px;
            }
            p {
                font-family: "Nunito Sans", sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #000000;
            }
        }
    }

    &-circle {
        &-animation {
            animation: fadeIn;
            animation-duration: 1s;
        }
    }

    &-icons {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        &-animation {
            animation: rotateIn;
            animation-duration: 3s;
        }
    }

    &-phone {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        &-animation {
            animation: fadeInLeft;
            animation-duration: 1s;
        }
    }
}

.free-lunch-img {
    margin-right: 40px !important;
    background: none;
}

.have-lunch {
    @media all and (min-width: 0) and (max-width: 900px) {
        display: none;
    }
}
