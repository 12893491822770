.defi {
  &__text {
    width: 51%;
    margin-right: 55px;

    @media all and (min-width: 0) and (max-width: 900px) {
      width: 55%;
    }

    @media all and (min-width: 0) and (max-width: 550px) {
      width: 100%;
    }
  }

  &-text {
    width: 100%;
    max-width: 433px;

    &-title {
      margin: 0 0 24px;
      font-family: "Sen", sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 44px;
      line-height: 53px;
      color: #000000;

      span {
        color: #bd1d83;
      }
    }

    &-info {
      margin: 0;
      font-family: "Nunito Sans", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #737380;
    }

    &-attention {
      display: flex;
      flex-direction: row;
      margin: 28px 0 41px;
      align-items: center;

      img {
        width: 40px;
        height: 40px;
        margin-right: 19px;
      }

      p {
        font-family: "Nunito Sans", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
      }
    }
  }

  &-download {
    @media all and (max-width: 359px) and (max-width: 768px) {
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }

    img:first-child {
      margin-right: 15px;

      @media all and (min-width: 300px) and (max-width: 768px) {
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }

    img:last-child {
      border-radius: 10px;
    }
  }

  &-circle {
    &-animation {
      animation: fadeIn;
      animation-duration: 1s;
    }
  }

  &-icons {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &-animation {
      animation: rotateIn;
      animation-duration: 3s;
    }
  }

  &-phone {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &-animation {
      animation: fadeInLeft;
      animation-duration: 1s;
    }
  }

  &-img {
    position: relative;
    padding: 20px 20px 0;
    overflow: hidden;
    border-radius: 39px;
    background: linear-gradient(180deg, #ffbfe7 0%, #ffeec9 100%);

    @media all and (min-width: 0) and (max-width: 768px) {
      padding: 10px 10px 0;
    }

    &--not-bg {
      background: none;
    }
  }
}