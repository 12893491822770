.team {
  margin: 100px 0;

  @media all and (min-width: 0) and (max-width: 768px) {
    margin: 40px 0;
  }
}

.team__head {
  width: 100%;
  margin-bottom: 40px;

  span {
    background-image: url('../../assets/img/team-decor.svg');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% 20px;
  }

  @media all and (min-width: 768px) and (max-width: 1024px) {}

  @media all and (min-width: 0) and (max-width: 768px) {
    margin-bottom: 20px;
  }
}

.team__title {
  margin: 0;
  font-size: 80px;
  line-height: 106%;
  letter-spacing: -0.04em;
  color: #1f2024;

  @media all and (max-width: 1200px) and (max-width: 1300px) {
    font-size: 70px;
  }

  @media all and (min-width: 0) and (max-width: 768px) {
    font-size: 45px;
  }
}

.team__label {
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin-bottom: 34px;

  @media all and (min-width: 0) and (max-width: 767px) {
    margin-bottom: 24px;
    column-gap: 10px;
  }
}

.team__label-text {
  white-space: nowrap;
  font-weight: 600;
  font-size: 30px;
  line-height: 151%;
  letter-spacing: -0.03em;
  color: #bd1d83;

  @media all and (min-width: 0) and (max-width: 767px) {
    font-size: 20px;
  }
}

.team__label-line {
  max-width: 100%;
}

.team__content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  column-gap: 40px;
  row-gap: 34px;

  @media all and (min-width: 0) and (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.team__info {
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  @media all and (min-width: 0) and (max-width: 767px) {
    row-gap: 20px;
  }
}

.team__info-text {
  font-size: 25px;
  line-height: 135%;
  letter-spacing: -0.03em;
  color: #1f2024;

  span {
    color: #bd1d83;
  }

  @media all and (min-width: 0) and (max-width: 767px) {
    font-size: 20px;
  }
}

.team__info-btn {
  background: #ffcd3a;

  @media all and (min-width: 0) and (max-width: 767px) {
    width: 100%;
    justify-content: space-between;
  }
}

.team__picture {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 40px;
  background: linear-gradient(180deg, #ffbfe7 0%, #ffeec9 100%);
  border-radius: 16px;
  

  @media all and (min-width: 0) and (max-width: 767px) {
    padding: 30px 20px;
  }
}

.team__picture-inner {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.team__picture-img {
  width: 118px;
  height: 118px;
  border-radius: 100%;
  overflow: hidden;
  object-fit: cover;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.team__picture-texts {}

.team__picture-name {
  font-size: 22px;
  line-height: 151%;
  letter-spacing: -0.03em;
  color: #1f2024;
}

.team__picture-position {
  padding: 4px 6px;
  background-color: #fff;
  border-radius: 46px;
  font-size: 12px;
  line-height: 137%;
  text-align: center;
  color: #23232e;
}