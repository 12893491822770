.hero {
  width: 100%;
  position: relative;
  margin-top: 160px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 140px;

  @media all and (max-width: 1200px) and (max-width: 1300px) {
    margin-top: 140px;
  }

  @media all and (min-width: 0) and (max-width: 768px) {
    margin-bottom: 100px;
  }

  @media all and (min-width: 0) and (max-width: 550px) {
    flex-wrap: wrap;
    margin-top: 130px;
    margin-bottom: 80px;
  }

  .slick-slide {
    @media all and (min-width: 0) and (max-width: 550px) {
      width: 300px !important;
    }
  }

  &-image {
    // max-width: 291px;
    // max-height: 702px;

    @media all and (min-width: 359px) and (max-width: 1200px) {
      width: 100%;
      order: 2;
      margin-top: 0;
    }
  }

  .slider-wrapper {
    width: 50%;
    margin-right: -200px;

    @media all and (min-width: 0) and (max-width: 1560px) {
      margin-right: -100px;
    }

    @media all and (min-width: 0) and (max-width:1380px) {
      margin-right: -50px;
    }

    @media all and (min-width: 0) and (max-width: 1240px) {
      width: 40%;
      margin-right: 0;
    }

    @media all and (min-width: 0) and (max-width: 1200px) {
      width: 30%;
    }

    @media all and (min-width: 0) and (max-width: 830px) {
      width: 35%;
    }

    @media all and (min-width: 0) and (max-width: 550px) {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 40px;
    }
  }

  .slick-slider {
    @media all and (min-width: 0) and (max-width: 550px) {
      max-width: 205px;
    }
  }

  .slick-arrow {
    @media all and (min-width: 0) and (max-width: 550px) {
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: #d9d9d9;
      transition: 0.2s;
    }

    &:hover {
      background-color: #ffcd3a;
    }
  }

  .slick-prev {
    @media all and (min-width: 0) and (max-width: 550px) {
      left: -70px;
    }
  }

  .slick-next {
    @media all and (min-width: 0) and (max-width: 550px) {
      right: -66px;
    }
  }

  .slick-arrow::before {
    content: "";
  }

  .slick-dots {
    display: block;
    right: 0;
    transform: rotate(90deg);
    bottom: 350px;
    position: absolute;

    @media all and (min-width: 0) and (max-width: 1240px) {
      right: -160px;
    }

    @media all and (min-width: 0) and (max-width: 1200px) {
      right: -120px;
      bottom: 245px;
    }

    @media all and (min-width: 0) and (max-width: 722px) {
      right: -95px;
      bottom: 182px;
    }

    li button:before {
      font-size: 12px;
    }

    .slick-active button:before {
      color: #ffcc3a;
    }
  }

  .small-button {
    padding: 5px 10px;
    color: white;
    font-weight: 600;
    border-radius: 50px;
    background: #bd1d83;
    font-size: 10px;
  }

  &-mainContent {
    @media all and (min-width: 0) and (max-width: 550px) {
      width: 100%;
    }

    &-title {
      position: relative;

      @media all and (min-width: 359px) and (max-width: 1200px) {
        width: 100%;
      }

      .title {
        position: relative;
        z-index: 1;
        text-align: left;

        &__first-part {
          color: #bd1d83;
          font-weight: 700;
        }

        &__second-part {
          position: relative;
        }
      }

      .yellow-pattern {
        position: absolute;
        right: -50px;
        bottom: 0;

        @media all and (min-width: 0) and (max-width: 722px) {
          width: 170px;
          right: -5px;
        }

        @media all and (min-width: 0) and (max-width: 450px) {
          width: 120px;
        }
      }

      h1 {
        font-family: "Futura PT", sans-serif;
        font-style: normal;
        font-size: 113px;
        font-weight: 400;
        line-height: 96%;
        color: black;
        margin: 0;

        @media all and (min-width: 0) and (max-width: 1200px) {
          font-size: 90px;
        }

        @media all and (min-width: 0) and (max-width: 900px) {
          font-size: 70px;
        }

        @media all and (min-width: 0) and (max-width: 620px) {
          font-size: 60px;
        }

        @media all and (min-width: 0) and (max-width: 550px) {
          font-size: 75px;
        }

        @media all and (min-width: 0) and (max-width: 450px) {
          font-size: 55px;
        }
      }
    }

    &-secondary-title {
      margin-bottom: 14px;
    }

    &-tertiary-title {
      margin-bottom: 45px;
    }

    &-box {
      margin-top: 52px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media all and (min-width: 0) and (max-width: 450px) {
        margin-top: 30px;
      }

      &-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 40px;

        &--mobile {
          display: none;

          @media all and (min-width: 0) and (max-width: 550px) {
            display: flex;
            margin-bottom: 0;
          }
        }

        &--desktop {
          @media all and (min-width: 0) and (max-width: 550px) {
            display: none;
          }
        }

        @media all and (min-width: 0) and (max-width: 900px) {
          align-items: flex-end;
        }

        .h4 {
          margin-top: 10px;
          margin-bottom: 15px;
          font-size: 25px;

          @media all and (min-width: 0) and (max-width: 900px) {
            font-size: 15px;
            margin-bottom: 10px;
            margin-top: 5px;
          }
        }

        &-text {
          font-family: "Futura PT Book", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 26px;
          color: #737380;
          width: 211px;
        }

        &-images {
          margin-right: 20px;
        }

        &-news {
          display: block;

          &-twitter {
            margin: 10px 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            span {
              color: #737380;
            }
          }

          &-title {
            margin: 0;
            font-family: "Futura PT", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 31px;
            color: #000000;
          }

          &-new {
            margin: 5px 0 12px;
            width: 242px;
            font-family: "Futura PT Book", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 23px;
            color: #333333;
          }
        }
      }
    }
  }
}

.hero .slider-wrapper {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    // right: -50%;
    right: -50%;
    top: -10%;
    width: 300%;
    height: 125%;

    background-image: url('/assets/newImg/Gradients.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    user-select: none;
    pointer-events: none;
    z-index: -1;

    @media (max-width: 550px) {
      top: 0;
      height: 100%;
    }
  }

}

.slick-dots {
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: fit-content;
  padding: 2px;
  background: #f9f9f9;
  border-radius: 40px;
  border: 1px solid #00000010;
}

.slick-slide {
  border: 0 !important;
  outline: 0 !important;

  &:active,
  &:focus,
  &:focus-visible {
    border: 0 !important;
    outline: 0 !important;
  }

  &>* {
    display: grid !important;
    // min-height: 600px !important;
    height: 100%;

    &:focus-visible {
      border: 0 !important;
      outline: 0 !important;
    }
  }

  img {
    margin: auto;
    width: 100% !important;
    // height: 100% !important;
    object-fit: contain;

    @media (min-width: 0) and (max-width: 550px) {
      margin: 0 auto;
      width: 300px !important;
      object-fit: contain;
    }
  }
}

.hero .slick-slider {
  @media (min-width: 0) and (max-width: 550px) {
    max-width: 300px;
  }
}

.hero .slick-prev {
  left: -20px;
  z-index: 2;
}

.hero .slick-next {
  right: -20px;
  z-index: 2;
}