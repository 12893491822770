.mobile-app-dowloads {
    &__apple {
        padding: 7px 17px;
    }

    &__apple,
    &__google {
        border: 1px solid #1f2024;
        border-radius: 5px;
    }

    a > img {
        @media all and (min-width: 0px) and (max-width: 730px) {
            width: 120px;
            height: 35px;
        }
    }
}
