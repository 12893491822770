.media {
  padding: 100px 0;

  @media all and (min-width: 0) and (max-width: 768px) {
    padding: 50px 0;
  }
}

.media__head {
  width: 100%;
  margin-bottom: 80px;

  span {
    background-image: url('../../assets/img/media-decor.svg');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% 20px;
  }

  @media all and (min-width: 768px) and (max-width: 1024px) {}

  @media all and (min-width: 0) and (max-width: 768px) {
    margin-bottom: 40px;
  }
}

.media__title {
  margin: 0;
  font-size: 80px;
  line-height: 106%;
  letter-spacing: -0.04em;
  color: #1f2024;

  @media all and (max-width: 1200px) and (max-width: 1300px) {
    font-size: 70px;
  }

  @media all and (min-width: 0) and (max-width: 768px) {
    font-size: 45px;
  }
}

.media__content {}

.media__form {
  display: flex;
  align-items: flex-start;
  column-gap: 80px;

  @media all and (min-width: 0) and (max-width: 900px) {
    column-gap: 40px;
  }

  @media all and (min-width: 0) and (max-width: 767px) {
    flex-direction: column;
    row-gap: 50px;
  }
}

.media__downloads {
  flex: 0 0 280px;

  @media all and (min-width: 0) and (max-width: 900px) {
    flex: 0 0 240px;
  }

  @media all and (min-width: 0) and (max-width: 767px) {
    flex: 0 1 auto;
    width: 100%;
  }
}

.media__form-label {
  margin-bottom: 24px;
  font-size: 30px;
  line-height: 151%;
  letter-spacing: -0.03em;
  color: #1f2024;

  @media all and (min-width: 0) and (max-width: 900px) {
    font-size: 20px;
  }

  @media all and (min-width: 0) and (max-width: 767px) {
    margin-bottom: 14px;
  }
}

.media__download {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;
  padding: 40px 20px;
  margin-bottom: 12px;
  border: 1px solid #000;
  border-radius: 12px;

  font-size: 22px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #000;

  @media (hover: hover) {
    &:hover {
      border-color: #bd1d83;
      color: #bd1d83;
    }
  }

  @media all and (min-width: 0) and (max-width: 900px) {
    padding: 20px 12px;
    column-gap: 10px;
    font-size: 15px;

    span {
      margin-right: auto;
    }
  }

}

.media__download-btn {
  text-transform: inherit;

  @media all and (min-width: 0) and (max-width: 900px) {
    margin-top: 20px;
  }

  @media all and (min-width: 0) and (max-width: 767px) {
    width: 100% !important;
    justify-content: space-between;
  }
}

.media__inqueries {
  flex: 1 1 auto;

  @media all and (min-width: 0) and (max-width: 767px) {
    width: 100%;
  }
}

.media__blocks {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
  row-gap: 24px;
  margin-bottom: 24px;

  @media all and (min-width: 0) and (max-width: 900px) {
    column-gap: 12px;
  }

  @media all and (min-width: 0) and (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 14px;
  }
}

.media__block {
  display: flex;
  align-items: center;
  column-gap: 14px;

  @media all and (min-width: 0) and (max-width: 900px) {
    column-gap: 10px;
  }
}

.media__block-pic {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  object-fit: cover;

  @media all and (min-width: 0) and (max-width: 900px) {
    width: 50px;
    height: 50px;
  }
}

.media__block-info {}

.media__block-name {
  font-weight: 600;
  font-size: 18px;
  line-height: 151%;
  letter-spacing: -0.03em;
  color: #1f2024;

  @media all and (min-width: 0) and (max-width: 900px) {
    font-size: 15px;
  }
}

.media__block-descr {
  font-size: 14px;
  line-height: 151%;
  letter-spacing: -0.03em;
  color: #1f2024;

  a {
    white-space: nowrap;
    color: #1f2024;
    text-decoration: underline;

    @media (hover: hover) {
      &:hover {
        color: #bd1d83;
      }
    }
  }

  @media all and (min-width: 0) and (max-width: 900px) {
    font-size: 10px;
  }
}

.media__inputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
  row-gap: 12px;
  margin-bottom: 12px;

  @media all and (min-width: 0) and (max-width: 900px) {
    column-gap: 12px;
  }

  @media all and (min-width: 0) and (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.media__input {
  width: 100%;
  padding: 20px;
  border: 1px solid #cfcfcf;
  border-radius: 12px;
  outline: 0;
  backdrop-filter: blur(7px);
  background: rgba(239, 239, 239, 0.3);

  font-weight: 400;
  font-size: 19px;
  line-height: 105%;
  letter-spacing: -0.01em;
  color: #000;

  &::placeholder {
    font-family: Graphik LCG, Futura PT, system-ui;
    font-weight: 400;
    font-size: 19px;
    line-height: 105%;
    letter-spacing: -0.01em;
    color: #000;
  }

  @media (hover: hover) {
    &:hover {
      border-color: #bd1d83;
    }
  }

  @media all and (min-width: 0) and (max-width: 900px) {
    padding: 16px 18px;
  }

  @media all and (min-width: 0) and (max-width: 767px) {
    font-size: 15px;

    &::placeholder {
      font-size: 15px;
    }
  }
}

.media__input--textarea {
  resize: none;
  height: 200px;

  @media all and (min-width: 0) and (max-width: 900px) {
    height: 120px;
  }
}

.media__btn {
  margin-top: 20px;
  min-width: 180px;
  justify-content: space-between;
}

.media__releases {
  margin: 100px 0;
}

.media__releases-columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
  row-gap: 30px;
  margin-bottom: 34px;

  @media all and (min-width: 0) and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 50px;
  }
}

.media__releases-column {
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  .media__releases-row {
    &:nth-child(n + 5) {
      display: none;
    }
  }
}

.media__releases-label {
  font-weight: 600;
  font-size: 30px;
  line-height: 151%;
  letter-spacing: -0.03em;
  color: #1f2024;

  @media all and (min-width: 0) and (max-width: 768px) {
    font-size: 20px;
  }
}

.media__releases-row {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 16px 24px;
  backdrop-filter: blur(7px);
  background: rgba(239, 239, 239, 0.3);

  @media all and (min-width: 0) and (max-width: 768px) {
    padding: 16px;
  }

}

.media__releases-data {
  font-size: 15px;
  line-height: 105%;
  letter-spacing: -0.01em;
  color: #bd1d83;
}

.media__releases-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;

  font-weight: 600;
  font-size: 22px;
  line-height: 134%;
  letter-spacing: -0.03em;
  text-decoration: underline;
  color: #000;
  transition: color 0.2s linear;

  svg {
    transition: transform 0.2s linear;
  }

  @media (hover:hover) {
    &:hover {
      color: #bd1d83;

      svg {
        transform: translateX(5px);
      }
    }
  }

  @media all and (min-width: 0) and (max-width: 768px) {
    font-size: 18px;

    svg {
      display: none;
    }
  }
}

.media__releases-btn {
  margin: 0 auto;

  @media all and (min-width: 0) and (max-width: 768px) {
    width: 100% !important;
    justify-content: space-between;
  }
}

.media__partners {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 20px;
  row-gap: 20px;
  margin: 100px 0;

  @media all and (min-width: 0) and (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    column-gap: 10px;
    row-gap: 10px;
    margin: 50px 0;
  }
}

.media__partner {
  display: grid;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  overflow: hidden;

  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }

  @media all and (min-width: 0) and (max-width: 768px) {}
}

.media__partner--yellow {
  background-color: #fae635;
}

.media__partner--black {
  background-color: #181818;
}

.media__partner--light-blue {
  background-color: #d6e7f8;
}

.media__partner--blue {
  background-color: #344da1;
}

.media__partner--purple {
  background-color: #400190;
}

.media__partner--dark-green {
  background-color: #33424A;
}

.media__releases-columns.view-more {
  .media__releases-column {
    .media__releases-row:nth-child(n + 5) {
      display: flex;
    }
  }
}