.members {
  position: relative;
  margin: 100px 0;

  @media all and (min-width: 0) and (max-width: 768px) {
    margin: 50px 0;
  }
}

.members__head {
  width: 100%;
  margin-bottom: 80px;

  span {
    color: #bd1d83;
    background-image: url('../../assets/img/members-decor.svg');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% 20px;
  }

  @media all and (min-width: 768px) and (max-width: 1024px) {}

  @media all and (min-width: 0) and (max-width: 768px) {
    margin-bottom: 40px;
  }
}

.members__title {
  margin: 0;
  font-size: 80px;
  line-height: 106%;
  letter-spacing: -0.04em;
  color: #1f2024;

  @media all and (max-width: 1200px) and (max-width: 1300px) {
    font-size: 70px;
  }

  @media all and (min-width: 0) and (max-width: 768px) {
    font-size: 45px;
  }
}

.members__slider {
  position: static;
  overflow: visible;

  .swiper-pagination {
    position: static;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    margin: 30px 0;

    @media all and (min-width: 0) and (max-width: 768px) {
      column-gap: 4px;
      margin: 20px 0 0;
    }
  }

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: #d9d9d9;
    opacity: 1;

    @media all and (min-width: 0) and (max-width: 768px) {
      width: 8px;
      height: 8px;
    }
  }

  .swiper-pagination-bullet-active {
    background-color: #ffcd3a;
  }

  .swiper-button-prev {
    left: -5vw;
    z-index: 3;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: #ffcd3a;
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='14' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 7H2M2 7L8.25926 1M2 7L8.25926 13' stroke='black' stroke-opacity='0.72' stroke-width='1.6' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;

    &.swiper-button-disabled {
      background-color: #f6f6f6;
      opacity: 1;
    }

    @media all and (min-width: 0) and (max-width: 1440px) {
      display: none;
    }
  }

  .swiper-button-next {
    right: -5vw;
    z-index: 3;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: #ffcd3a;
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='14' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 7H13M13 7L6.74074 1M13 7L6.74074 13' stroke='black' stroke-width='1.6' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;

    &.swiper-button-disabled {
      background-color: #f6f6f6;
      opacity: 1;
    }

    @media all and (min-width: 0) and (max-width: 1440px) {
      display: none;
    }
  }

  .swiper-slide {
    flex: 0 0 320px;

    &:last-child {
      .roadmap__slide-line {
        flex: 1 1 auto;
      }
    }

    @media all and (min-width: 0) and (max-width: 768px) {
      flex: 0 0 220px;
    }
  }
}

.members__slide {
  display: flex;
  flex-direction: column;
}

.members__slide-picture {
  position: relative;
  width: fit-content;
  margin-bottom: 18px;

  @media all and (min-width: 0) and (max-width: 768px) {
    margin-bottom: 12px;
  }
}

.members__slide-pic {
  width: 160px;
  height: 160px;
  border-radius: 100%;
  overflow: hidden;
  object-fit: cover;

  @media all and (min-width: 0) and (max-width: 768px) {
    width: 100px;
    height: 100px;
  }
}

.members__slide-icon {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 35px;
  height: 35px;
  border-radius: 100%;

  @media all and (min-width: 0) and (max-width: 768px) {
    width: 22px;
    height: 22px;
    right: 4px;
    bottom: 4px;
  }
}

.members__slide-info {
  margin-bottom: 20px;

  @media all and (min-width: 0) and (max-width: 768px) {
    margin-bottom: 16px;
  }
}

.members__slide-name {
  margin: 0;
  font-size: 30px;
  line-height: 151%;
  letter-spacing: -0.03em;
  color: #1f2024;

  @media all and (min-width: 0) and (max-width: 768px) {
    font-size: 22px;
  }
}

.members__slide-position {
  margin: 0;
  width: fit-content;
  padding: 4px 8px;
  background-color: #fff502;
  border-radius: 46px;
  font-size: 16px;
  line-height: 137%;
  text-align: center;
  color: #23232e;

  @media all and (min-width: 0) and (max-width: 768px) {
    font-size: 13px;
  }
}

.members__slide-footer {
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  &.expanded .members__slide-text {
    // max-height: 400px;
    -ms-line-clamp: inherit;
    -webkit-line-clamp: inherit;
    line-clamp: inherit;
    /* Показываем весь текст при раскрытии */
  }
}

.members__slide-text {
  font-size: 18px;
  line-height: 151%;
  letter-spacing: -0.03em;
  color: #1f2024;

  position: relative;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  -ms-line-clamp: 4;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  display: -webkit-box;
  display: box;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  // max-height: 106px;
  /* Начальная высота, равная примерно 4 строкам текста */
  transition: all 0.3s ease;
  /* Добавляем плавный переход */

  @media all and (min-width: 0) and (max-width: 768px) {
    font-size: 15px;
  }
}

.members__slide-btn {
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-size: 16px;
  line-height: 118%;
  letter-spacing: -0.02em;
  // text-transform: uppercase;
  color: #bd1d83;

  svg {
    transition: 0.2s linear;
  }

  @media (hover: hover) {
    &:hover {
      svg {
        transform: translateX(4px);
      }
    }
  }

  @media all and (min-width: 0) and (max-width: 768px) {
    font-size: 14px;
  }
}