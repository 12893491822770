.rectangle-separator {
  padding: 80px 0;
  margin-bottom: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 29px;
  // background: linear-gradient(123deg, #bd1d83 21.42%, #ffc46b 86.86%, #fff502 100%);
  background-image: url('/assets/img/rectangle-separator-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;

  @media all and (min-width: 300px) and (max-width: 768px) {
    margin-bottom: 100px;
    padding: 50px 0;
  }

  @media all and (min-width: 300px) and (max-width: 550px) {
    padding: 30px 0;
  }

  &__title {
    margin: 0;
    font-size: 70px;
    text-align: center;
    color: white;
    margin-bottom: 70px;

    @media all and (min-width: 300px) and (max-width: 900px) {
      font-size: 60px;
    }

    @media all and (min-width: 300px) and (max-width: 768px) {
      font-size: 48px;
      margin-bottom: 25px;
    }

    @media all and (min-width: 300px) and (max-width: 550px) {
      font-size: 42px;
    }

    span {
      color: #fff502;
    }
  }

  &__icons {
    display: flex;
    justify-content: center;

    @media all and (min-width: 300px) and (max-width: 550px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__link {
    &:first-child {
      @media all and (min-width: 300px) and (max-width: 550px) {
        margin-bottom: 10px;
      }
    }

    &:not(:last-child) {
      margin-right: 17px;

      @media all and (min-width: 550px) and (max-width: 768px) {
        margin-right: 10px;
      }

      @media all and (min-width: 300px) and (max-width: 550px) {
        margin-right: 0;
      }
    }

    @media all and (min-width: 300px) and (max-width: 550px) {
      text-align: center;
      width: 17%;
    }
  }

  &__icon {
    width: 48px;
    height: 48px;

    @media all and (min-width: 300px) and (max-width: 900px) {
      width: 40px;
      height: 40px;
    }

    @media all and (min-width: 300px) and (max-width: 768px) {
      width: 36px;
      height: 36px;
    }

    @media all and (min-width: 300px) and (max-width: 550px) {
      width: 45px;
      height: 45px;
    }
  }
}

.subscribe {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 29px;
  // background: linear-gradient(123deg, #bd1d83 21.42%, #ffc46b 86.86%, #fff502 100%);
  background-image: url('/assets/img/subscribe-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  padding: 70px 60px;
  margin-bottom: 100px;

  @media all and (min-width: 0) and (max-width: 1050px) {
    padding: 50px 40px;
  }

  @media all and (min-width: 0) and (max-width: 800px) {
    padding: 30px 20px;
  }

  @media all and (min-width: 0) and (max-width: 550px) {
    flex-direction: column;
  }

  .textfieldw {
    width: 75%;
    margin-right: 10px;

    @media all and (min-width: 0) and (max-width: 550px) {
      margin-bottom: 20px;
      width: 100%;
    }
  }

  .main-btn {
    min-width: 116px;
    white-space: nowrap;

    @media all and (min-width: 0) and (max-width: 550px) {
      max-width: 182px;
      width: 100%;
    }
  }

  &-form {
    display: flex;
    width: 50%;

    @media all and (min-width: 0) and (max-width: 1050px) {
      min-width: 420px;
    }

    @media all and (min-width: 0) and (max-width: 768px) {
      min-width: 320px;
    }

    @media all and (min-width: 0) and (max-width: 550px) {
      width: 100%;
      flex-direction: column;
      align-items: center;
      min-width: 100%;
    }

    &__input {
      max-width: 100%;
    }
  }

  &__text {
    font-size: 31px;

    @media all and (min-width: 0) and (max-width: 768px) {
      margin-bottom: 20px;
      width: 100%;
    }

    @media all and (min-width: 0) and (max-width: 550px) {
      margin-bottom: 30px;
      text-align: center;
    }
  }
}
