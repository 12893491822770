.management-section {
  margin: 100px 0;

  @media all and (min-width: 0) and (max-width: 768px) {
    margin: 40px 0;
  }
}

.management-section__head {
  width: 100%;
  margin-bottom: 30px;

  span {
    background-image: url('../../assets/img/management-section-decor.svg');
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: 100% 20px;
  }

  @media all and (min-width: 768px) and (max-width: 1024px) {}

  @media all and (min-width: 0) and (max-width: 768px) {
    margin-bottom: 20px;
  }
}

.management-section__title {
  margin: 0 0 40px 0;
  font-size: 80px;
  line-height: 106%;
  letter-spacing: -0.04em;
  color: #1f2024;

  @media all and (max-width: 1200px) and (max-width: 1300px) {
    font-size: 70px;
  }

  @media all and (min-width: 0) and (max-width: 768px) {
    margin-bottom: 20px;
    font-size: 45px;
  }
}

.management-section__text {
  font-size: 21px;
  line-height: 151%;
  letter-spacing: -0.03em;
  color: #1f2024;

  @media all and (min-width: 0) and (max-width: 768px) {
    font-size: 18px;
  }
}

.management-section__founders {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 34px;
  row-gap: 34px;

  @media all and (min-width: 0) and (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.management-section__founder {
  display: grid;
  grid-template-columns: 160px 1fr;
  align-content: baseline;
  column-gap: 40px;

  @media all and (max-width: 1200px) and (max-width: 1300px) {
    column-gap: 20px;
    row-gap: 20px;
    grid-template-columns: 120px 1fr;
  }

  @media all and (min-width: 0) and (max-width: 991px) {
    grid-template-columns: 100px 1fr;
  }
}

.management-section__founder-naiming {
  height: fit-content;

  @media all and (min-width: 0) and (max-width: 767px) {
    align-self: center;
  }
}

.management-section__founder-picture {
  position: relative;
  width: fit-content;
  height: 100px;


  @media all and (max-width: 1200px) and (max-width: 1300px) {
    height: 80px;
  }

  @media all and (min-width: 0) and (max-width: 991px) {
    height: 60px;
  }

  @media all and (min-width: 0) and (max-width: 767px) {
    height: fit-content;
  }
}

.management-section__founder-img {
  width: 160px;
  height: 160px;
  border-radius: 100%;
  object-fit: cover;

  @media all and (max-width: 1200px) and (max-width: 1300px) {
    width: 120px;
    height: 120px;
  }

  @media all and (min-width: 0) and (max-width: 991px) {
    width: 100px;
    height: 100px;
  }
}



.management-section__founder-info {
  grid-column-start: 2;
  display: flex;
  flex-direction: column;

  @media all and (min-width: 0) and (max-width: 767px) {
    grid-column-start: 1;
    grid-column: span 2;
  }
}

.management-section__founder-row {
  margin-bottom: 20px;

  @media all and (min-width: 0) and (max-width: 991px) {
    margin-bottom: 12px;
  }
}

.management-section__founder-name {
  margin: 0;
  font-size: 37px;
  line-height: 151%;
  letter-spacing: -0.03em;
  color: #1f2024;

  @media all and (min-width: 0) and (max-width: 991px) {
    font-size: 22px;
  }
}

.management-section__founder-position {
  width: fit-content;
  padding: 2px 4px;
  border-radius: 46px;
  background-color: #fff502;
  font-size: 16px;
  line-height: 137%;
  text-align: center;
  color: #23232e;

  @media all and (min-width: 0) and (max-width: 991px) {
    font-size: 13px;
  }

}

.management-section__founder-descr {
  margin-bottom: 12px;
  font-size: 18px;
  line-height: 151%;
  letter-spacing: -0.03em;
  color: #1f2024;

  @media all and (min-width: 0) and (max-width: 991px) {
    font-size: 15px;
  }
}

.management-section__founder-btn {
  display: flex;
  column-gap: 10px;
  width: fit-content;
  // margin-top: auto;

  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.02em;
  // text-transform: uppercase;
  color: #bd1d83;

  svg {
    transition: 0.2s linear;
  }

  @media (hover: hover) {
    &:hover {
      svg {
        transform: translateX(4px);
      }
    }
  }
}

.management-section__founder-subrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
}

.management-section__founder-subrow:not(:last-child) {
  margin-bottom: 10px;
}

.management-section__founder-text {
  font-size: 18px;
  line-height: 151%;
  letter-spacing: -0.03em;
  color: #1f2024;

  @media all and (min-width: 0) and (max-width: 991px) {
    font-size: 15px;
  }
}

.management-section__founder-link {
  display: flex;
  align-items: center;
  column-gap: 4px;
  text-decoration: underline;
  font-size: 18px;
  line-height: 151%;
  letter-spacing: -0.03em;
  color: #000;

  img {
    width: 20px;
    height: 20px;
  }

  @media (hover:hover) {
    &:hover {
      color: #0274B3;
    }
  }

  @media all and (min-width: 0) and (max-width: 991px) {
    font-size: 15px;

    img {
      width: 16px;
      height: 16px;
    }
  }
}

.management-section__founder-words {
  border-top: 1px solid #e3e3e3;
  padding-top: 12px;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 132%;
  letter-spacing: -0.03em;
  color: #1f2024;

  @media all and (min-width: 0) and (max-width: 991px) {
    padding-top: 8px;
    margin-bottom: 4px;
    font-size: 14px;
  }
}

.management-section__founder-author {
  font-weight: 400;
  font-size: 16px;
  line-height: 151%;
  letter-spacing: -0.03em;
  text-align: right;
  color: #9fa2ae;

  @media all and (min-width: 0) and (max-width: 991px) {
    font-size: 15px;
  }
}

.management-section__managers {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 80px;
  row-gap: 34px;

  @media all and (min-width: 0) and (max-width: 991px) {
    column-gap: 24px;
  }

  @media all and (min-width: 0) and (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.management-section__manager {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;

  @media all and (min-width: 0) and (max-width: 768px) {
    display: grid;
    grid-template-columns: 100px 1fr;
    justify-items: start;
    align-items: center;
    column-gap: 20px;
    row-gap: 20px;
  }
}

.management-section__manager-picture {
  position: relative;
  width: fit-content;
  margin-bottom: 18px;

  @media all and (min-width: 0) and (max-width: 768px) {
    margin-bottom: 0;
  }
}

.management-section__manager-pic {
  width: 160px;
  height: 160px;
  border-radius: 100%;
  overflow: hidden;
  object-fit: cover;

  @media all and (min-width: 0) and (max-width: 768px) {
    width: 100px;
    height: 100px;
  }
}

.management-section__manager-icon {
  position: absolute;
  bottom: 4px;
  right: 4px;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  object-fit: cover;

  @media all and (min-width: 0) and (max-width: 768px) {
    width: 24px;
    height: 24px;
  }
}

.management-section__manager-info {
  margin-bottom: 20px;

  @media all and (min-width: 0) and (max-width: 768px) {
    margin-bottom: 10px;
  }
}

.management-section__manager-name {
  font-size: 30px;
  line-height: 151%;
  letter-spacing: -0.03em;
  color: #1f2024;

  @media all and (min-width: 0) and (max-width: 768px) {
    font-size: 24px;
  }
}

.management-section__manager-position {
  width: fit-content;
  padding: 2px 6px;
  border-radius: 46px;
  background-color: #fff502;

  font-size: 16px;
  line-height: 137%;
  text-align: center;
  color: #23232e;

  @media all and (min-width: 0) and (max-width: 768px) {
    font-size: 13px;
  }
}

.management-section__manager-footer {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  height: 100%;

  @media all and (min-width: 0) and (max-width: 768px) {
    grid-column: span 2;
  }

  &.expanded .management-section__manager-descr {
    // max-height: 400px;
    -ms-line-clamp: inherit;
    -webkit-line-clamp: inherit;
    line-clamp: inherit;
    /* Показываем весь текст при раскрытии */
  }
}

.management-section__manager-descr {
  position: relative;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  -ms-line-clamp: 4;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  display: -webkit-box;
  display: box;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  // max-height: 106px;
  /* Начальная высота, равная примерно 4 строкам текста */
  transition: all 0.3s ease;
  /* Добавляем плавный переход */

  font-size: 18px;
  line-height: 151%;
  letter-spacing: -0.03em;
  color: #1f2024;

  @media all and (min-width: 0) and (max-width: 768px) {
    font-size: 15px;
  }
}

.management-section__manager-btn {
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: fit-content;
  // margin-top: auto;

  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.02em;
  // text-transform: uppercase;
  color: #bd1d83;

  svg {
    transition: 0.2s linear;
  }

  @media (hover: hover) {
    &:hover {
      svg {
        transform: translateX(4px);
      }
    }
  }
}

.management-section__founder-descr {
  position: relative;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  -ms-line-clamp: 4;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  display: -webkit-box;
  display: box;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  // max-height: 106px;
  /* Начальная высота, равная примерно 4 строкам текста */
  transition: all 0.3s ease;
  /* Добавляем плавный переход */
}

.management-section__founder-info.expanded .management-section__founder-descr {
  // max-height: 400px;
  -ms-line-clamp: inherit;
  -webkit-line-clamp: inherit;
  line-clamp: inherit;
  /* Показываем весь текст при раскрытии */
}

.management-section__founder-linkedin {
  position: absolute;
  bottom: -54px;
  right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media all and (max-width: 1200px) and (max-width: 1300px) {
    bottom: -44px;
  }

  @media all and (min-width: 0) and (max-width: 768px) {
    bottom: 0px;
  }
}

.management-section__founder-icon {
  width: 34px;
  height: 34px;
  border-radius: 100%;
  object-fit: cover;

  @media all and (min-width: 0) and (max-width: 768px) {
    width: 24px;
    height: 24px;
  }
}