.our-cases {
  padding: 80px 0;

  @media all and (min-width: 0) and (max-width: 768px) {
    padding: 40px 0;
  }
}

.our-cases__head {
  max-width: 580px;
  width: fit-content;

  margin-bottom: 20px;
  // padding: 0 40px;

  span {
    background-image: url('../../assets/img/our-cases-decor.svg');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% 20px;
  }

  @media all and (min-width: 768px) and (max-width: 1024px) {
    padding: 0 32px;
  }

  @media all and (min-width: 0) and (max-width: 768px) {
    padding: 0 20px;
  }
}

.our-cases__title {
  margin: 0;
  font-size: 80px;
  line-height: 106%;
  letter-spacing: -0.04em;
  color: #1f2024;

  @media all and (max-width: 1200px) and (max-width: 1300px) {
    font-size: 70px;
  }

  @media all and (min-width: 0) and (max-width: 768px) {
    font-size: 45px;
  }
}

.our-cases__rows {
  display: flex;
  flex-direction: column;
  row-gap: 50px;

  @media all and (min-width: 768px) and (max-width: 1024px) {
    row-gap: 32px;
  }

  @media all and (min-width: 0) and (max-width: 768px) {
    row-gap: 20px;
  }
}

.our-cases__row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  justify-items: center;
  column-gap: 20px;
  row-gap: 40px;
  padding: 60px 40px;

  @media all and (min-width: 768px) and (max-width: 1024px) {
    padding: 40px 32px;
  }

  @media all and (min-width: 0) and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 30px 20px;
  }
}

.our-cases__row {
  background: rgba(244, 244, 244, 0.5);
}

.our-cases__row--white {
  padding-top: 0;
  background-color: transparent;
}

.our-cases__row:nth-child(even) {

  .our-cases__info {
    order: 2;

    @media all and (min-width: 0) and (max-width: 767px) {
      order: inherit;
    }
  }
}

.our-cases__info {
  display: flex;
  flex-direction: column;
}

.our-cases__label {
  margin: 0 0 16px 0;
  font-size: 30px;
  line-height: 143%;
  letter-spacing: -0.03em;
  color: #bd1d83;

  @media all and (min-width: 768px) and (max-width: 1024px) {
    font-size: 28px;
  }

  @media all and (min-width: 0) and (max-width: 768px) {
    font-size: 24px;
  }
}

.our-cases__label--purple {
  color: #bd1d83;
}

.our-cases__text {
  margin: 0 0 40px 0;
  font-size: 21px;
  line-height: 151%;
  letter-spacing: -0.03em;
  color: #1f2024;

  @media all and (min-width: 768px) and (max-width: 1024px) {
    margin: 0 0 34px 0;
    font-size: 18px;
  }

  @media all and (min-width: 0) and (max-width: 768px) {
    margin: 0 0 24px 0;
    font-size: 16px;
  }
}

.our-cases__btn {
  width: 246px;
}

.our-cases__picture {
  margin-top: auto;

  margin-bottom: -60px;

  @media all and (min-width: 768px) and (max-width: 1024px) {
    margin-bottom: -40px;
  }

  @media all and (min-width: 0) and (max-width: 768px) {
    margin-bottom: -30px;
  }

  img {
    max-width: 100%;
  }
}

.our-cases__row--airlines {
  .our-cases__picture {}
}

.our-cases__row--banks {
  .our-cases__picture {
    text-align: right;
    margin-right: -40px;

    @media all and (min-width: 768px) and (max-width: 1024px) {
      margin-right: -32px;
    }

    @media all and (min-width: 0) and (max-width: 768px) {
      margin-right: -20px;
    }
  }
}

.our-cases__row--social {
  .our-cases__picture {
    margin-left: -40px;

    @media all and (min-width: 768px) and (max-width: 1024px) {
      margin-left: -32px;
    }

    @media all and (min-width: 0) and (max-width: 768px) {
      margin-left: -20px;
    }
  }
}