.header {
  height: 90px;
  width: 100%;
  background: #ffffff;
  border-bottom: 1px solid #e6e6eb;
  display: flex;
  align-items: center;
  z-index: 101;
  position: fixed;

  @media all and (min-width: 0px) and (max-width: 1240px) {
    padding: 0 20px;
  }

  @media all and (min-width: 0) and (max-width: 722px) {
    height: 75px;
  }

  &-logo {
    display: block;

    img {
      @media all and (min-width: 0) and (max-width: 722px) {
        width: 95px;
        height: 18px;
      }
    }
  }

  &-logo-box {
    @media all and (min-width: 0px) and (max-width: 1202px) {
      margin-right: 50px;
    }

    @media all and (min-width: 0) and (max-width: 722px) {
      margin-right: 0;
    }

    margin-right: 100px;
    z-index: 2;
  }

  @media all and (min-width: 1300px) and (max-width: 1440px) {
    padding: 0 20px;
  }

  &-container {
    width: 100%;
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    position: relative;

    @media all and (min-width: 0px) and (max-width: 1132px) {
      justify-content: space-between;
    }

    &-additional {
      height: 108px;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: nowrap;

      &-lang {
        @media all and (min-width: 0) and (max-width: 722px) {
          border-radius: 7px;
          border: 1px solid rgba(0, 0, 0, 0.12);
        }

        &__text,
        &__icon {
          @media all and (min-width: 0) and (max-width: 722px) {
            display: none;
          }
        }

        @media all and (min-width: 0px) and (max-width: 1132px) {
          order: 2;
        }

        margin: 0 20px;

        @media all and (min-width: 0) and (max-width: 722px) {
          margin-right: 0 20px 0 30px;
        }

        button {
          display: flex;
          flex-direction: row;
          align-items: center;

          span {
            font-family: "Futura PT Book", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            text-transform: uppercase;
            color: #000000;
          }
        }
      }

      &-login {
        margin-right: 16px;
        display: flex;
        align-items: center;

        &__text--secondary {
          @media all and (min-width: 0) and (max-width: 722px) {
            display: none;
          }
        }

        @media all and (min-width: 0px) and (max-width: 1132px) {
          order: 3;
        }

        a {
          background: #000;
          border-radius: 12px;
          padding: 11px 36px;

          @media all and (min-width: 0) and (max-width: 722px) {
            padding: 3px 11px;
            border-radius: 7px;
          }

          span {
            font-family: "Futura PT", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #fff;
          }
        }
      }

      &-buyEpan {
        &-modal {
          display: flex;
          flex-direction: column;

          &-disabled {
            pointer-events: none;

            &:hover {
              font-family: "Futura PT Book", sans-serif !important;
            }
          }

          a {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 56px;
            width: 142px;
            padding: 12px 0;
            font-family: "Futura PT Book", sans-serif;
            transition: ease 0.25s;

            &:hover {
              font-family: "Futura PT", sans-serif;
              transition: ease 0.25s;
            }

            &:last-child {
              cursor: not-allowed;

              span {
                color: #bdbdbd !important;
              }
            }

            img {
              height: 32px;
              width: 32px;
            }

            span {
              margin-left: 12px;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 18px;
              color: #333333;
            }

            border-bottom: 1px solid #e6e6eb;

            &:last-child {
              border-bottom: none;
            }
          }
        }

        &-modalLang {
          display: flex;
          flex-direction: column;

          button {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 48px;
            border-bottom: 1px solid #e6e6eb;
            transition: ease 0.25s;

            &:hover {
              transition: ease 0.25s;

              span {
                font-family: "Futura PT", sans-serif !important;
              }
            }

            &:last-child {
              border-bottom: none;
            }

            img {
              margin-right: 12px;
            }

            span {
              font-family: "Futura PT Book", sans-serif;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 18px;
              color: #333333;
              transition: ease 0.25s;
            }
          }
        }

        &-payp {
          margin-right: 10px;

          @media all and (min-width: 0) and (max-width: 722px) {
            margin-right: 0;
            padding: 3px;
          }
        }

        &-arrow {
          margin-left: 17px;
        }

        button {
          padding: 10px 20px;
          background: linear-gradient(270deg, #ef9392 0%, #da5bcf 100%);
          border-radius: 12px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          span {
            font-family: "Futura PT", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
          }
        }
      }
    }
  }
}

.navigation {
  @media all and (min-width: 0px) and (max-width: 1132px) {
    display: none;
  }

  .center {
    width: 90%;
  }

  &-actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 32px;
  }

  .header-container-additional-lang {
    display: flex;
    justify-content: center;
    padding-bottom: 54px;
    border-bottom: 1px solid #e6e6eb;
  }
}

.header-container-additional-lang button {
  padding: 6px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 7px;
}

.header-container-additional-lang__text {
  font-weight: 600;
}

.open {
  transform: translateX(300px);
  transition: transform 0.12s ease-out;
}

.langDisabled {
  cursor: not-allowed;
  pointer-events: none;
  filter: grayscale(100%);

  span {
    color: #bdbdbd !important;
  }
}

.Burger {
  @media all and (min-width: 0px) and (max-width: 722px) {
    display: none;
    padding-left: 8px !important;
  }
}

.BurgerInner:before {
  width: 100% !important;
}

.BurgerActive {
  .BurgerInner:before {
    width: 100% !important;
  }
}

.BurgerInner,
.BurgerInner:before,
.BurgerInner:after {
  background-color: #000 !important;
}

// .css-3bmhjh-MuiPaper-root-MuiPopover-paper {
//   min-width: 126px;
//   padding: 0 24px;
//   margin-left: -6px;
//   // margin-left: -20px;
// }

.header-container-additional-buyEpan-modalLang {
  padding: 0 20px;
}

.header-container-additional-buyEpan-modalLang button img {
  width: 24px;
  height: 24px;
  object-fit: cover;
}