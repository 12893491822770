.cookies {
  // max-width: 900px;
  margin: 108px auto 60px;
}

.cookies p,
.cookies li,
.cookies th,
.cookies td {
  font-family: "Futura PT Book", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #737380;
}

.cookies a {
  color: #bd1d83;
  text-decoration: underline;
}

.cookies p {
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.cookies h1,
.cookies h2 {
  font-family: "Futura PT Book", sans-serif;
}

.cookies table {
  border-collapse: collapse;
  border: 1px black solid;
}

.cookies th,
.cookies td {
  padding: 8px;
  border: 1px black solid;
}