.modal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
}

.modal-content {
  position: relative;
  width: 100%;
  padding: 20px 30px;
  margin: auto;
  border-radius: 24px;
  background: #fbfbfb;
  opacity: 0;
  overflow-y: auto;
  transform: translateY(-50px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

  @media all and (min-width: 0) and (max-width: 767px) {
    padding: 20px 16px;
    height: 100%;
    border-radius: 0;
  }
}

.modal-content--large {
  max-width: 1200px;
}

.modal-content--small {
  max-width: 580px;
}

.modal-content--middle {
  max-width: 820px;

  .modal__form {
    max-width: 100%;
  }
}

.modal.show .modal-content {
  opacity: 1;
  transform: translateY(0);
}

.modal-content {
  display: flex;
  flex-direction: column;
}

.modal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 54px;
  width: 100%;

  @media all and (min-width: 0) and (max-width: 767px) {
    margin-bottom: 30px;
  }
}

.modal__title {
  margin: 0;
  font-size: 37px;
  line-height: 106%;
  letter-spacing: -0.04em;
  color: #1f2024;

  @media all and (min-width: 0) and (max-width: 767px) {
    text-align: center;
    font-size: 30px;
  }
}

.close-modal-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  cursor: pointer;

  @media all and (min-width: 0) and (max-width: 767px) {
    display: none;
  }
}

.close-modal-btn--mob {
  display: none;

  @media all and (min-width: 0) and (max-width: 767px) {
    display: flex;
    width: fit-content;
    justify-content: center;
    margin: auto auto 0;

    font-size: 17px;
    line-height: 227%;
    letter-spacing: -0.03em;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    text-align: center;
    color: #1f2024;
  }
}

.modal__inner {
  @media all and (min-width: 0) and (max-width: 767px) {
    height: auto;
  }
}

.modal__cards {
  display: flex;
  align-items: center;
  padding-top: 100px;

  @media all and (min-width: 0) and (max-width: 767px) {
    padding-top: 0;
    margin: 0 -6px;
  }
}

.modal__texts {
  margin-bottom: 20px;

  @media all and (min-width: 0) and (max-width: 1200px) {
    width: 20%;
    margin-bottom: 80px;
  }

  @media all and (min-width: 0) and (max-width: 767px) {
    display: none;
  }
}

.modal__card-text {
  border-bottom: 1px solid #f2f2f2;
  font-size: 20px;
  line-height: 198%;
  letter-spacing: -0.03em;
  color: #1f2024;

  @media all and (min-width: 0) and (max-width: 1200px) {
    font-size: 17px;
    line-height: 203%;
    border-color: #e7e7e7;
  }

  @media all and (min-width: 0) and (max-width: 767px) {
    line-height: 160%;
    border: 0;
  }
}

.mySwiper {}

.modal__card-slider {
  overflow: visible;

  @media all and (min-width: 0) and (max-width: 767px) {
    padding: 10px 0;
    overflow: hidden;
  }


  .swiper-slide {
    width: 312px;

    @media all and (min-width: 0) and (max-width: 1200px) {
      width: 160px;
    }

    @media all and (min-width: 0) and (max-width: 767px) {
      width: 260px;
    }
  }

  .swiper-wrapper {
    @media all and (min-width: 0) and (max-width: 767px) {
      justify-content: flex-start;
    }
  }
}

.modal__card {
  padding: 26px 32px;
  border: 1px solid #f4f4f4;
  border-radius: 20px;
  background-color: #fff;

  .modal__card-infos .modal__card-rows:first-child {
    display: none;

    @media all and (min-width: 0) and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  @media all and (min-width: 0) and (max-width: 1200px) {
    padding: 0;
    border: 0;
  }
}

.modal__card-top {
  position: relative;
  margin-top: -140px;
  margin-bottom: 10px;

  @media all and (min-width: 0) and (max-width: 767px) {
    margin-top: 0;
  }
}

.modal__card-label {
  min-height: 40px;
  margin: 0 auto 4px;
  width: fit-content;
  border-radius: 32px;
  font-size: 33px;
  line-height: 106%;
  letter-spacing: -0.04em;
  text-align: center;
  color: #1f2024;

  @media all and (min-width: 0) and (max-width: 1200px) {
    font-size: 23px;
    min-height: 30px;
  }
}

.modal__card-label--have {
  padding: 2px 10px;
  background-color: #fff502;
}

.modal__card-pic {
  height: auto;
  max-width: 260px;
  width: 100%;

  img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.modal__card-infos {
  margin-bottom: 20px;

  @media all and (min-width: 0) and (max-width: 767px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.modal__card-rows {}

.modal__card-row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 0;
  border-bottom: 1px solid #e7e7e7;
  font-size: 20px;
  line-height: 160%;
  letter-spacing: -0.03em;
  text-align: center;
  color: #000;

  @media all and (min-width: 0) and (max-width: 1200px) {
    font-size: 17px;
  }

  @media all and (min-width: 0) and (max-width: 767px) {
    height: 36px;
  }
}

.modal__card-btn {
  width: 100%;
  justify-content: space-between;
}

.main-btn {}

.main-btn--yellow {}

.modal__card-row--minus span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: #e7e7e7;
  font-size: 20px;
  line-height: 233%;
  letter-spacing: -0.03em;
  text-align: center;
  color: #000;

  @media all and (min-width: 0) and (max-width: 1200px) {
    width: 26px;
    height: 26px;
  }
}

.modal__card-row--plus span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: #ffcd3a;
  font-size: 20px;
  line-height: 233%;
  letter-spacing: -0.03em;
  text-align: center;
  color: #000;

  @media all and (min-width: 0) and (max-width: 1200px) {
    width: 26px;
    height: 26px;
  }
}

.modal__form {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  max-width: 380px;
  width: 100%;
  margin: 0 auto;

  @media all and (min-width: 0) and (max-width: 767px) {
    row-gap: 24px;
    height: 100%;
  }
}

.modal__form-card {
  max-width: 380px;
  width: 100%;
  height: 240px;

  img {
    max-width: 100%;
    width: 100%;
    height: 100%;
  }

  @media all and (min-width: 0) and (max-width: 767px) {
    height: 200px;
  }
}

.modal__form-inputs {
  display: flex;
  flex-direction: column;
  row-gap: 14px;
}

.modal__form-input {
  width: 100%;
  padding: 18px 20px;
  border: 1px solid #cfcfcf;
  border-radius: 12px;
  outline: 0;
  backdrop-filter: blur(7px);
  background: rgba(239, 239, 239, 0.3);

  font-weight: 400;
  font-size: 19px;
  line-height: 105%;
  letter-spacing: -0.01em;
  color: #000;

  &::placeholder {
    font-family: Graphik LCG, Futura PT, system-ui;
    font-weight: 400;
    font-size: 19px;
    line-height: 105%;
    letter-spacing: -0.01em;
    color: #000;
  }

  @media (hover: hover) {
    &:hover {
      border-color: #bd1d83;
    }
  }

  @media all and (min-width: 0) and (max-width: 900px) {
    padding: 16px 18px;
  }

  @media all and (min-width: 0) and (max-width: 767px) {
    font-size: 15px;

    &::placeholder {
      font-size: 15px;
    }
  }
}

.modal__form-btn {
  margin: 0 auto;
  min-width: 180px;
  justify-content: space-between;

  @media all and (min-width: 0) and (max-width: 767px) {
    width: 100%;
    margin-top: auto;
  }
}

.modal-content--middle {
  .modal__header {
    margin-bottom: 32px;
  }
}

.modal__title--xl {
  font-size: 54px;

  @media all and (min-width: 0) and (max-width: 767px) {
    font-size: 32px;
  }
}

.modal__form-inputs--grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-width: 100%;
  row-gap: 12px;
  column-gap: 16px;

  .modal__form-input--textarea {
    grid-column: span 2;
  }

  @media all and (min-width: 0) and (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);

    .modal__form-input--textarea {
      grid-column: span 1;
    }
  }
}

.modal__form-input--textarea {
  resize: none;
  height: 200px;
}

.modal__form--row {
  flex-direction: row;
  column-gap: 12px;
  max-width: 100%;

  .modal__form-inputs {
    flex-shrink: 1;
    flex-grow: 1;
  }

  @media all and (min-width: 0) and (max-width: 767px) {
    flex-direction: column;
  }
}