.signup-newsletter__wrapper {
  position: relative;
  padding: 50px 0 140px;
  margin-bottom: 90px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -50%;
    width: 1000%;
    height: 100%;
    background: linear-gradient(180deg, #fff 0%, #f3f3f3 100%);
    z-index: -1;
  }


  @media all and (max-width: 1200px) and (max-width: 1300px) {
    padding: 40px 0 80px;
    margin-bottom: 50px;
  }

  @media all and (min-width: 0) and (max-width: 768px) {
    padding: 40px 0;
    margin-bottom: 0;
  }
}

.signup-newsletter {
  padding: 54px 174px 76px;
  border-radius: 29px;
  background-image: url('../../assets/images/signup-newsletter-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;


  @media all and (max-width: 1200px) and (max-width: 1300px) {
    padding: 40px 60px;
    border-radius: 6px;
    background-position: center;
  }

  @media all and (min-width: 0) and (max-width: 768px) {
    padding: 34px 20px;
    background-position: bottom;
  }
}

.signup-newsletter__inner {}

.signup-newsletter__title {
  max-width: 720px;
  margin: 0 0 48px 0;

  font-size: 70px;
  line-height: 106%;
  letter-spacing: -0.04em;
  text-align: center;
  color: #fff;


  @media all and (max-width: 1200px) and (max-width: 1300px) {
    font-size: 48px;
  }

  @media all and (min-width: 0) and (max-width: 768px) {
    margin-bottom: 24px;
    font-size: 42px;
  }
}

.signup-newsletter__row {
  display: flex;
  align-items: center;
  column-gap: 10px;

  @media all and (min-width: 0) and (max-width: 768px) {
    flex-direction: column;
    row-gap: 10px;
  }
}

.signup-newsletter__input {
  flex: 1 1 auto;
  width: 100%;
  height: 55px;
  padding: 18px 20px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 12px;
  backdrop-filter: blur(7px);
  background: rgba(255, 255, 255, 0.3);
  outline: 0;
  transition: border 0.2s linear;

  font-family: Graphik LCG, Futura PT, system-ui;
  font-weight: 400;
  font-size: 19px;
  line-height: 105%;
  letter-spacing: -0.01em;
  color: #fff;

  &::placeholder {
    font-family: Graphik LCG, Futura PT, system-ui;
    font-weight: 400;
    font-size: 19px;
    line-height: 105%;
    letter-spacing: -0.01em;
    color: #fff;
  }

  @media (hover: hover) {
    &:hover {
      border-color: #bd1d83;
    }
  }

  @media all and (min-width: 0) and (max-width: 768px) {
    font-size: 15px;

    &::placeholder {
      font-size: 15px;
    }
  }
}

.signup-newsletter__btn {
  flex: 0 0 182px;
  justify-content: space-between;

  @media all and (min-width: 0) and (max-width: 768px) {
    flex: 1 1 auto;
    min-width: 182px;
    margin-top: 15px;
    font-size: 15px;
  }
}