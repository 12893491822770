.hero-section {
  position: relative;
  padding: 100px 0;
  margin-top: 80px;

  @media all and (min-width: 0) and (max-width: 768px) {
    padding: 60px 0;
    margin-top: 40px;
  }
}

.hero-section__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media all and (min-width: 0) and (max-width: 768px) {
    flex-direction: column;
    row-gap: 40px;
  }
}

.hero-section__info {
  flex: 0 1 70%;

  @media all and (min-width: 0) and (max-width: 768px) {
    flex: 0 1 auto;
  }
}

.hero-section__head {
  max-width: 700px;
  width: fit-content;
  margin-bottom: 40px;

  span {
    background-image: url('../../assets/img/hero-section-decor.svg');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% 25px;
  }

  @media all and (max-width: 1200px) and (max-width: 1300px) {
    max-width: 500px;
  }

  @media all and (min-width: 768px) and (max-width: 1024px) {}

  @media all and (min-width: 0) and (max-width: 768px) {}
}

.hero-section__title {
  margin: 0;
  font-size: 112px;
  line-height: 90%;
  letter-spacing: -0.04em;
  color: #1f2024;

  @media all and (max-width: 1200px) and (max-width: 1300px) {
    font-size: 80px;
  }

  @media all and (min-width: 0) and (max-width: 768px) {
    font-size: 55px;
  }
}

.hero-section__text {
  max-width: 545px;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 24px;
  line-height: 134%;
  letter-spacing: -0.03em;
  color: #1f2024;

  span {
    color: #bd1d83;
  }

  @media all and (max-width: 1200px) and (max-width: 1300px) {
    max-width: 400px;
    margin-bottom: 30px;
    font-size: 24px;
  }

  @media all and (min-width: 0) and (max-width: 768px) {
    margin-bottom: 20px;
    font-size: 20px;
  }
}

.hero-section__btn {
  margin-top: auto;
  min-width: 246px;
  justify-content: space-between;

  @media all and (min-width: 0) and (max-width: 768px) {
    position: absolute !important;
    bottom: 50px;
    left: 0;
    z-index: 2;
    width: 100% !important;
  }
}

.hero-section__picture {
  position: relative;
  flex: 0 1 30%;

  @media all and (min-width: 0) and (max-width: 768px) {
    flex: 0 1 auto;
  }


  &::before {
    content: '';
    position: absolute;
    top: 20%;
    left: 0;
    width: 100%;
    height: 50%;
    filter: blur(118px);
    background: linear-gradient(180deg, rgba(255, 39, 177, 0.3) 0%, rgba(255, 198, 67, 0.29) 100%);
    z-index: -1;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -15%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200%;
    height: 30%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 10%) 0%, #fff 100%);
    user-select: none;
    pointer-events: none;

    @media all and (min-width: 0) and (max-width: 580px) {
      height: 20%;
      bottom: 0;
      transform: translate(-50%, 0%);
    }
  }

  img {
    max-width: 100%;
  }
}

.hero-section--saas {
  .hero-section__info {
    flex: 0 1 59%;
  }

  .hero-section__picture {
    flex: 0 1 50%;
  }

  .hero-section__picture {
    &::after {
      content: none;
    }
  }

  .hero-section__head {
    @media all and (min-width: 0) and (max-width: 767px) {
      span {
        background-size: 100% 20px;
      }
    }
  }
}