.footer {
    &__hiring {
        display: block;
        position: absolute;
        background-color: #fff502;
        border-radius: 8px;
        left: 75px;
        top: -6px;
        transform: rotate(8deg);
        padding: 5px 10px;
        font-size: 14px;
        width: 93px;

        @media all and (min-width: 768px) and (max-width: 920px) {
            left: 55px;
            font-size: 12px;
            width: 84px;
        }

        @media all and (min-width: 0px) and (max-width: 550px) {
            left: 60px;
            font-size: 12px;
            width: 84px;
        }
    }

    &__parent-hiring {
        position: relative;
    }

    .footer__title {
        margin-bottom: 30px;
        font-family: "Futura PT", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 21px;
        line-height: 23px;
        color: #000;
        transition: ease 0.15s;

        @media all and (min-width: 320px) and (max-width: 1090px) {
            font-size: 18px;
        }

        @media all and (min-width: 320px) and (max-width: 920px) {
            font-size: 15px;
        }

        @media all and (min-width: 359px) and (max-width: 768px) {
            font-size: 18px;
        }

        @media all and (min-width: 0px) and (max-width: 500px) {
            font-size: 15px;
        }

        @media all and (min-width: 0) and (max-width: 768px) {
            margin-bottom: 10px;
        }
    }

    .footer__list-item {
        font-family: "Futura PT", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 21px;
        line-height: 23px;
        color: #000;
        transition: ease 0.15s;
        cursor: pointer;

        &--last {
            position: relative;
        }

        @media all and (min-width: 320px) and (max-width: 1090px) {
            font-size: 18px;
        }

        @media all and (min-width: 320px) and (max-width: 920px) {
            font-size: 15px;
        }

        @media all and (min-width: 359px) and (max-width: 768px) {
            font-size: 18px;
        }

        @media all and (min-width: 0px) and (max-width: 500px) {
            font-size: 15px;
        }

        &:hover {
            transition: ease 0.15s;
            text-shadow: 0 0 1px black;
        }
    }

    .footer__list-item:not(:last-child) {
        margin-bottom: 25px;

        @media all and (min-width: 0) and (max-width: 768px) {
            margin-bottom: 10px;
        }
    }

    &-container {
        position: relative;

        @media all and (min-width: 359px) and (max-width: 1220px) {
            padding: 40px 25px 0;
        }
        @media all and (min-width: 359px) and (max-width: 768px) {
            align-items: center;
            // clip-path: polygon(0 5%, 100% 0, 100% 100%, 0 100%);
        }

        @media all and (min-width: 0px) and (max-width: 550px) {
            padding: 40px 15px 0;
        }

        padding-top: 0;

        &-angle {
            position: absolute;
            top: -1px;
            left: 50%;
            transform: translate(-50%, 0);
        }

        &-content {
            z-index: 1;
            display: flex;
            max-width: 1208px;
            margin: 0 auto;
            flex-direction: column;
            position: relative;

            &-box {
                display: flex;
                flex-direction: column;
                margin-bottom: 60px;

                &-logo {
                    width: 171px;
                    margin-bottom: 40px;
                }
                &-info {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    flex-wrap: wrap;

                    &-address {
                        width: 25%;
                        order: 2;

                        @media all and (min-width: 768px) and (max-width: 1090px) {
                            width: 21%;
                        }

                        @media all and (min-width: 0) and (max-width: 768px) {
                            width: 50%;
                        }

                        @media all and (min-width: 0) and (max-width: 590px) {
                            width: 60%;
                        }

                        @media all and (min-width: 0) and (max-width: 500px) {
                            order: 1;
                            width: 100%;
                        }

                        &__content {
                            @media all and (min-width: 0) and (max-width: 768px) {
                                display: flex;
                                justify-content: space-between;
                            }

                            @media all and (min-width: 0) and (max-width: 500px) {
                                justify-content: start;
                            }
                        }

                        .first {
                            margin-bottom: 15px;

                            @media all and (min-width: 0) and (max-width: 500px) {
                                width: 50%;
                            }
                        }

                        p {
                            font-family: "Futura PT Book", sans-serif;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 18px;
                            line-height: 26px;
                            color: #000000;

                            @media all and (min-width: 320px) and (max-width: 920px) {
                                font-size: 15px;
                            }

                            @media all and (min-width: 0) and (max-width: 768px) {
                                font-size: 18px;
                            }

                            @media all and (min-width: 0px) and (max-width: 500px) {
                                font-size: 15px;
                            }

                            a {
                                color: inherit !important;
                                transition: ease 0.25s;

                                @media all and (min-width: 0) and (max-width: 768px) {
                                    font-size: 23px;
                                }

                                &:hover {
                                    transition: ease 0.25s;
                                    color: #1476fd !important;
                                }
                            }
                        }

                        @media all and (min-width: 0) and (max-width: 500px) {
                            margin-bottom: 30px;
                        }
                    }

                    &-links {
                        order: 2;
                        width: 20%;

                        @media all and (min-width: 0) and (max-width: 1090px) {
                            width: 17%;
                        }

                        &--about {
                            width: 25%;

                            @media all and (min-width: 0) and (max-width: 1090px) {
                                width: 22%;
                            }

                            @media all and (min-width: 0) and (max-width: 768px) {
                                width: 50%;
                            }

                            @media all and (min-width: 0) and (max-width: 590px) {
                                width: 40%;
                            }

                            @media all and (min-width: 0) and (max-width: 500px) {
                                width: 50%;
                            }
                        }

                        &--faq {
                            @media all and (min-width: 0) and (max-width: 768px) {
                                width: 50%;
                                margin-bottom: 50px;
                                order: 1;
                            }

                            @media all and (min-width: 0) and (max-width: 590px) {
                                width: 40%;
                            }

                            @media all and (min-width: 0) and (max-width: 500px) {
                                width: auto;
                                margin-bottom: 30px;
                            }
                        }

                        nav {
                            display: flex;
                            flex-direction: column;

                            .footer-coming-soon {
                                margin-left: 10px;
                                background-color: black;
                                padding: 2px 8px 3px;
                                border-radius: 26px;
                                color: #f8d448;
                                &:hover {
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                    &-social {
                        width: 30%;
                        order: 3;
                        display: flex;
                        flex-direction: column;

                        @media all and (min-width: 0) and (max-width: 1090px) {
                            width: 35%;
                        }

                        @media all and (min-width: 0) and (max-width: 768px) {
                            width: 50%;
                        }

                        @media all and (min-width: 0) and (max-width: 590px) {
                            width: 60%;
                        }

                        @media all and (min-width: 0) and (max-width: 500px) {
                            width: 100%;
                        }

                        &__first-column,
                        &__second-column {
                            display: flex;
                            flex-direction: column;
                        }

                        &__first-column {
                            margin-right: 30px;

                            @media all and (min-width: 320px) and (max-width: 1090px) {
                                margin-right: 15px;
                            }

                            @media all and (min-width: 0) and (max-width: 500px) {
                                width: 50%;
                                margin-right: 0;
                            }
                        }

                        &__first-columns {
                            display: flex;
                            flex-direction: row;
                        }

                        &__title {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            margin-bottom: 30px;
                        }

                        &-item {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            margin-bottom: 15px;

                            &:last-child {
                                margin-bottom: 0;
                            }

                            img {
                                margin-right: 16px;
                            }

                            span {
                                font-family: "Futura PT", sans-serif;
                                font-style: normal;
                                font-weight: 300;
                                font-size: 21px;
                                line-height: 23px;
                                color: #000;
                                transition: ease 0.15s;

                                @media all and (min-width: 320px) and (max-width: 1090px) {
                                    font-size: 18px;
                                }

                                @media all and (min-width: 320px) and (max-width: 920px) {
                                    font-size: 15px;
                                }

                                @media all and (min-width: 359px) and (max-width: 768px) {
                                    font-size: 18px;
                                }

                                @media all and (min-width: 0px) and (max-width: 500px) {
                                    font-size: 15px;
                                }

                                &:hover {
                                    transition: ease 0.15s;
                                    text-shadow: 0 0 1px black;
                                }
                            }
                        }
                    }
                }
            }

            &-downloads {
                display: flex;
                justify-content: center;
                margin-bottom: 50px;
            }

            &-sponsors {
                display: flex;
                justify-content: center;
                margin-bottom: 60px;

                @media all and (min-width: 359px) and (max-width: 550px) {
                    flex-wrap: wrap;
                }

                &__item:not(:last-child) {
                    margin-right: 10px;
                }

                &__item--third {
                    @media all and (min-width: 359px) and (max-width: 550px) {
                        margin-right: 0 !important;
                        margin-bottom: 10px;
                    }
                }

                &__item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 112px;
                    height: 75px;
                    border-radius: 5px;
                    background: #f5f5f5;

                    @media all and (min-width: 359px) and (max-width: 550px) {
                        width: 30%;
                    }

                    img {
                        object-fit: scale-down;
                    }
                }
            }
        }
    }

    .company-brand {
        font-size: 21px;
        font-weight: 600;
        color: #bd1d83;

        @media all and (min-width: 320px) and (max-width: 1090px) {
            font-size: 18px;
        }

        @media all and (min-width: 320px) and (max-width: 920px) {
            font-size: 15px;
        }

        @media all and (min-width: 0) and (max-width: 768px) {
            font-size: 18px;
        }

        @media all and (min-width: 0px) and (max-width: 500px) {
            font-size: 15px;
        }
    }

    &-copy {
        margin: 0 auto;
        margin-bottom: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        @media all and (min-width: 359px) and (max-width: 1024px) {
            padding: 13px 25px;
            padding-bottom: 0;
        }

        p {
            font-family: "Futura PT Book", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 26px;
            text-align: center;
            color: #000000;
            opacity: 0.7;
        }
    }
    &-copy-bx {
        margin: 0 auto;
        padding: 13px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        @media all and (min-width: 359px) and (max-width: 1024px) {
            padding: 0;
            padding-bottom: 13px;
        }

        p {
            font-family: "Futura PT Book", sans-serif;
            font-style: normal;
            font-weight: normal;
            padding: 0px 25px;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            color: #000000;
            opacity: 0.7;

            @media all and (min-width: 0) and (max-width: 550px) {
                padding: 0 15px;
            }
        }
    }
}
