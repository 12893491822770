.partners {
  position: relative;
  padding: 90px 0;
  margin: 90px 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -500%;
    width: 1000%;
    height: 100%;
    background: linear-gradient(180deg, #fff 0%, #fff9fd 100%);
    z-index: -1;
  }

  @media all and (max-width: 1200px) and (max-width: 1300px) {
    margin: 60px 0;
    padding: 60px 0;
  }

  @media all and (min-width: 0) and (max-width: 768px) {
    margin: 40px 0 0;
    padding: 40px 0;
  }
}

.partners__head {
  width: fit-content;
  background-image: url('../../assets/img/partners-decor.svg');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100% 20px;
  margin-bottom: 20px;

  @media all and (min-width: 0) and (max-width: 768px) {
    margin-bottom: 20px;
  }
}

.partners__text {
  margin-bottom: 20px;
  font-size: 24px;

  @media all and (min-width: 0) and (max-width: 768px) {
    font-size: 20px;
  }
}

.partners__title {
  margin: 0;
  font-size: 80px;
  line-height: 106%;
  letter-spacing: -0.04em;
  color: #1f2024;

  @media all and (max-width: 1200px) and (max-width: 1300px) {
    font-size: 70px;
  }

  @media all and (min-width: 0) and (max-width: 768px) {
    font-size: 55px;
  }
}

.partners__list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: 1fr;
  column-gap: 18px;
  row-gap: 16px;

  @media all and (max-width: 1200px) and (max-width: 1300px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media all and (min-width: 0) and (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 10px;
    row-gap: 10px;
  }

  // @media all and (min-width: 0) and (max-width: 560px) {
  //   grid-template-columns: repeat(2, 1fr);
  //   column-gap: 4px;
  //   row-gap: 4px;
  // }
}

.partners__item {
  display: grid;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 10px;
  list-style: none;
  margin: 0;
  background: rgba(243, 240, 243, 0.8);
  border-radius: 16px;

  font-size: 33px;
  line-height: 106%;
  letter-spacing: -0.04em;
  text-align: center;
  color: #000;

  img {
    max-width: 100%;
    width: auto;
    height: auto;
    max-height: 160px;
    object-fit: cover;
  }

  // @media all and (min-width: 0) and (max-width: 768px) {
  //   height: 80px;

  //   img {
  //     width: 80px;
  //   }
  // }
}

.partners__more {
  margin-top: 20px;
  min-width: 220px;
  justify-content: space-between;
}