@font-face {
  font-family: 'Futura PT';
  src: url('./../assets/fonts/FuturaPT-Heavy.eot');
  src: local('Futura PT Heavy'), local('FuturaPT-Heavy'),
  url('./../assets/fonts/FuturaPT-Heavy.eot?#iefix') format('embedded-opentype'),
  url('./../assets/fonts/FuturaPT-Heavy.woff') format('woff'),
  url('./../assets/fonts/FuturaPT-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Futura PT';
  src: url('./../assets/fonts/FuturaPT-Medium.eot');
  src: local('Futura PT Medium'), local('FuturaPT-Medium'),
  url('./../assets/fonts/FuturaPT-Medium.eot?#iefix') format('embedded-opentype'),
  url('./../assets/fonts/FuturaPT-Medium.woff') format('woff'),
  url('./../assets/fonts/FuturaPT-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Futura PT';
  src: url('./../assets/fonts/FuturaPT-MediumObl.eot');
  src: local('Futura PT Medium Oblique'), local('FuturaPT-MediumObl'),
  url('./../assets/fonts/FuturaPT-MediumObl.eot?#iefix') format('embedded-opentype'),
  url('./../assets/fonts/FuturaPT-MediumObl.woff') format('woff'),
  url('./../assets/fonts/FuturaPT-MediumObl.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Futura PT Cond';
  src: url('./../assets/fonts/FuturaPTCond-Medium.eot');
  src: local('Futura PT Cond Medium'), local('FuturaPTCond-Medium'),
  url('./../assets/fonts/FuturaPTCond-Medium.eot?#iefix') format('embedded-opentype'),
  url('./../assets/fonts/FuturaPTCond-Medium.woff') format('woff'),
  url('./../assets/fonts/FuturaPTCond-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Futura PT';
  src: url('./../assets/fonts/FuturaPT-LightObl.eot');
  src: local('Futura PT Light Oblique'), local('FuturaPT-LightObl'),
  url('./../assets/fonts/FuturaPT-LightObl.eot?#iefix') format('embedded-opentype'),
  url('./../assets/fonts/FuturaPT-LightObl.woff') format('woff'),
  url('./../assets/fonts/FuturaPT-LightObl.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Futura PT Book';
  src: url('./../assets/fonts/FuturaPT-Book.eot');
  src: local('Futura PT Book'), local('FuturaPT-Book'),
  url('./../assets/fonts/FuturaPT-Book.eot?#iefix') format('embedded-opentype'),
  url('./../assets/fonts/FuturaPT-Book.woff') format('woff'),
  url('./../assets/fonts/FuturaPT-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Futura PT Cond';
  src: url('./../assets/fonts/FuturaPTCond-Bold.eot');
  src: local('Futura PT Cond Bold'), local('FuturaPTCond-Bold'),
  url('./../assets/fonts/FuturaPTCond-Bold.eot?#iefix') format('embedded-opentype'),
  url('./../assets/fonts/FuturaPTCond-Bold.woff') format('woff'),
  url('./../assets/fonts/FuturaPTCond-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Futura PT Book';
  src: url('./../assets/fonts/FuturaPT-BookObl.eot');
  src: local('Futura PT Book Oblique'), local('FuturaPT-BookObl'),
  url('./../assets/fonts/FuturaPT-BookObl.eot?#iefix') format('embedded-opentype'),
  url('./../assets/fonts/FuturaPT-BookObl.woff') format('woff'),
  url('./../assets/fonts/FuturaPT-BookObl.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Futura PT Cond Extra';
  src: url('./../assets/fonts/FuturaPTCond-ExtraBoldObl.eot');
  src: local('Futura PT Cond Extra Bold Oblique'), local('FuturaPTCond-ExtraBoldObl'),
  url('./../assets/fonts/FuturaPTCond-ExtraBoldObl.eot?#iefix') format('embedded-opentype'),
  url('./../assets/fonts/FuturaPTCond-ExtraBoldObl.woff') format('woff'),
  url('./../assets/fonts/FuturaPTCond-ExtraBoldObl.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Futura PT';
  src: url('./../assets/fonts/FuturaPT-Light.eot');
  src: local('Futura PT Light'), local('FuturaPT-Light'),
  url('./../assets/fonts/FuturaPT-Light.eot?#iefix') format('embedded-opentype'),
  url('./../assets/fonts/FuturaPT-Light.woff') format('woff'),
  url('./../assets/fonts/FuturaPT-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Futura PT Demi';
  src: url('./../assets/fonts/FuturaPT-BoldObl.eot');
  src: local('Futura PT Bold Oblique'), local('FuturaPT-BoldObl'),
  url('./../assets/fonts/FuturaPT-BoldObl.eot?#iefix') format('embedded-opentype'),
  url('./../assets/fonts/FuturaPT-BoldObl.woff') format('woff'),
  url('./../assets/fonts/FuturaPT-BoldObl.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Futura PT Demi';
  src: url('./../assets/fonts/FuturaPT-DemiObl.eot');
  src: local('Futura PT Demi Oblique'), local('FuturaPT-DemiObl'),
  url('./../assets/fonts/FuturaPT-DemiObl.eot?#iefix') format('embedded-opentype'),
  url('./../assets/fonts/FuturaPT-DemiObl.woff') format('woff'),
  url('./../assets/fonts/FuturaPT-DemiObl.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Futura PT Cond Extra';
  src: url('./../assets/fonts/FuturaPTCond-ExtraBold.eot');
  src: local('Futura PT Cond Extra Bold'), local('FuturaPTCond-ExtraBold'),
  url('./../assets/fonts/FuturaPTCond-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('./../assets/fonts/FuturaPTCond-ExtraBold.woff') format('woff'),
  url('./../assets/fonts/FuturaPTCond-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Futura PT Demi';
  src: url('./../assets/fonts/FuturaPT-Demi.eot');
  src: local('Futura PT Demi'), local('FuturaPT-Demi'),
  url('./../assets/fonts/FuturaPT-Demi.eot?#iefix') format('embedded-opentype'),
  url('./../assets/fonts/FuturaPT-Demi.woff') format('woff'),
  url('./../assets/fonts/FuturaPT-Demi.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Futura PT';
  src: url('./../assets/fonts/FuturaPT-HeavyObl.eot');
  src: local('Futura PT Heavy Oblique'), local('FuturaPT-HeavyObl'),
  url('./../assets/fonts/FuturaPT-HeavyObl.eot?#iefix') format('embedded-opentype'),
  url('./../assets/fonts/FuturaPT-HeavyObl.woff') format('woff'),
  url('./../assets/fonts/FuturaPT-HeavyObl.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Futura PT Extra';
  src: url('./../assets/fonts/FuturaPT-ExtraBoldObl.eot');
  src: local('Futura PT Extra Bold Oblique'), local('FuturaPT-ExtraBoldObl'),
  url('./../assets/fonts/FuturaPT-ExtraBoldObl.eot?#iefix') format('embedded-opentype'),
  url('./../assets/fonts/FuturaPT-ExtraBoldObl.woff') format('woff'),
  url('./../assets/fonts/FuturaPT-ExtraBoldObl.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Futura PT Cond Book';
  src: url('./../assets/fonts/FuturaPTCond-Book.eot');
  src: local('Futura PT Cond Book'), local('FuturaPTCond-Book'),
  url('./../assets/fonts/FuturaPTCond-Book.eot?#iefix') format('embedded-opentype'),
  url('./../assets/fonts/FuturaPTCond-Book.woff') format('woff'),
  url('./../assets/fonts/FuturaPTCond-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Futura PT';
  src: url('./../assets/fonts/FuturaPT-Bold.eot');
  src: local('Futura PT Bold'), local('FuturaPT-Bold'),
  url('./../assets/fonts/FuturaPT-Bold.eot?#iefix') format('embedded-opentype'),
  url('./../assets/fonts/FuturaPT-Bold.woff') format('woff'),
  url('./../assets/fonts/FuturaPT-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Futura PT Extra';
  src: url('./../assets/fonts/FuturaPT-ExtraBold.eot');
  src: local('Futura PT Extra Bold'), local('FuturaPT-ExtraBold'),
  url('./../assets/fonts/FuturaPT-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('./../assets/fonts/FuturaPT-ExtraBold.woff') format('woff'),
  url('./../assets/fonts/FuturaPT-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Futura PT Cond';
  src: url('./../assets/fonts/FuturaPTCond-BoldObl.eot');
  src: local('Futura PT Cond Bold Oblique'), local('FuturaPTCond-BoldObl'),
  url('./../assets/fonts/FuturaPTCond-BoldObl.eot?#iefix') format('embedded-opentype'),
  url('./../assets/fonts/FuturaPTCond-BoldObl.woff') format('woff'),
  url('./../assets/fonts/FuturaPTCond-BoldObl.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Futura PT Cond';
  src: url('./../assets/fonts/FuturaPTCond-MediumObl.eot');
  src: local('Futura PT Cond Medium Oblique'), local('FuturaPTCond-MediumObl'),
  url('./../assets/fonts/FuturaPTCond-MediumObl.eot?#iefix') format('embedded-opentype'),
  url('./../assets/fonts/FuturaPTCond-MediumObl.woff') format('woff'),
  url('./../assets/fonts/FuturaPTCond-MediumObl.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Futura PT Cond Book';
  src: url('./../assets/fonts/FuturaPTCond-BookObl.eot');
  src: local('Futura PT Cond Book Oblique'), local('FuturaPTCond-BookObl'),
  url('./../assets/fonts/FuturaPTCond-BookObl.eot?#iefix') format('embedded-opentype'),
  url('./../assets/fonts/FuturaPTCond-BookObl.woff') format('woff'),
  url('./../assets/fonts/FuturaPTCond-BookObl.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
