.addYourWallets-icons {
  width: 426px !important;
  height: 408px !important;
}

.addYourWallets {
  &-text {
    width: 100%;

    &-title {
      margin: 0 0 24px;
      font-family: "Futura PT", sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 80px;
      line-height: 106%;
      color: #000000;
      text-align: left;

      @media all and (min-width: 0) and (max-width: 1050px) {
        font-size: 48px;
      }

      @media all and (min-width: 0) and (max-width: 900px) {
        font-size: 40px;
      }

      span {
        position: relative;
        color: #bd1d83;
      }
    }

    &-attention {
      &--mobile {
        width: 100%;

        .main-btn {
          width: 100%;
          text-align: left;
        }

        @media all and (min-width: 551px) {
          display: none;
        }
      }

      &--desktop {
        @media all and (min-width: 0) and (max-width: 550px) {
          display: none;
        }
      }
    }

    &-info {
      margin: 0;
      margin-bottom: 75px;
      font-family: "Futura PT", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 21px;
      line-height: 150%;
      color: #1f2024;

      @media all and (min-width: 0) and (max-width: 1050px) {
        font-size: 15px;
      }

      @media all and (min-width: 0) and (max-width: 900px) {
        margin-bottom: 50px;
      }

      @media all and (min-width: 0) and (max-width: 550px) {
        margin-bottom: 30px;
      }

      &--mb28 {
        margin-bottom: 28px;
      }

      &--mb10 {
        margin-bottom: 10px;
      }

      &-container {
        &-hidden {
          height: 229px;
          overflow-y: hidden;
        }

        &-title {
          display: inline-block;
          margin: 20px 0;
        }

        &-button {
          display: flex;
          flex-direction: row;
          align-items: center;

          span {
            font-family: "Futura PT", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #bd1d83;
          }

          img {
            margin-left: 10px;
          }
        }
      }
    }
  }

  &-img {
    overflow: hidden;
  }

  &-coming-soon {
    position: relative;
    right: -5px;
    top: -38px;
    display: inline;
    padding: 6px 10px;
    background: #f3ba2f;
    box-shadow: 0 8px 16px -6px rgba(243, 186, 47, 0.71);
    border-radius: 16px 16px 16px 1px;
    font-family: Nunito Sans, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    @media all and (min-width: 359px) and (max-width: 425px) {
      right: 10px;
      top: -40px;
    }
  }

  &-circle {
    &-animation {
      animation: fadeIn;
      animation-duration: 1s;
    }
  }

  &-icons {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &-animation {
      animation: rotateIn;
      animation-duration: 3s;
    }
  }

  &-phone {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &-animation {
      animation: fadeInRight;
      animation-duration: 1s;
    }
  }
}

.addYourWallets-text-title span[data-label] {
  position: relative;
  color: #000;

  &::before {
    position: absolute;
    left: 100%;
    top: 0;
    content: attr(data-label);
    padding: 4px 6px;
    background-color: #fff502;
    border-radius: 10px;
    white-space: nowrap;
    font-size: 24px;
    line-height: 106%;
    letter-spacing: -0.05em;
    text-align: right;
    color: #000;

    @media all and (min-width: 0) and (max-width: 991px) {
      font-size: 20px;
    }
  }
}

.addYourWallets-text-title span[data-label="Coming soon"] {
  color: #bd1d83;

  &::before {
    left: auto;
    right: 0;
    transform: translateX(70%) rotate(30deg);

    @media all and (min-width: 0) and (max-width: 991px) {
      top: -10px;
    }
  }
}


.addYourWallets-text-title-border {
  background-image: url('../../assets/img/card-decor.svg');
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 100% 20px;
}

.addYourWallets-text-title-border--purple {
  white-space: nowrap;
  background-image: url('../../assets/img/1-click-decor.svg');
  background-size: 100% 30px;

  @media all and (min-width: 0) and (max-width: 1050px) {
    background-size: 100% 20px;
  }

  @media all and (min-width: 0) and (max-width: 767px) {
    background-size: 100% 16px;
  }
}

.addYourWallets-text-title-border--fiol {
  background-image: url('../../assets/img/minutes-decor.svg');
  background-size: 100% 30px;

  @media all and (min-width: 0) and (max-width: 767px) {
    background-size: 100% 16px;
  }
}