.statistics {
    padding: 16px;
    display: flex;

    @media all and (min-width: 0) and (max-width: 722px) {
        display: none;
    }

    @media all and (min-width: 0) and (max-width: 1200px) {
    }

    &-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: nowrap;
        min-width: 52px;

        &:first-child {
            margin-right: 30px;
        }

        &-title {
            display: flex;
            flex-direction: row;
            align-items: center;

            span {
                font-family: "Futura PT", sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 10px;
                line-height: 18px;
                color: rgba(31, 32, 36, 0.56);
            }
        }

        &-price {
            font-size: 14px;
            &:first-child {
                margin-left: 5px;
            }
        }
    }
}

.positive {
    color: #59b679;
    margin-left: 5px;
}

.negative {
    color: #df516a;
    margin-left: 5px;
}

.header-container-additional-statistics {
    background: transparent;
    border-radius: 0;
    padding: 0 0 0 16px;
}
